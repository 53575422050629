/* eslint-disable */
import React from 'react';
import {
    Card, CardBody, Col, Table, Button, Modal, ButtonToolbar
} from 'reactstrap';
import firebase from '../../../shared/config/firebase';
import { UserContext } from '../../App/UserContext';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import CenterLoader from '../../../shared/components/CenterLoader';

const firebaseUsers = firebase.database().ref('Users');

class AppointmentCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            pro: false,
            availability: {
                mon: {
                    vm: false,
                    nm: false
                },
                tue: {
                    vm: false,
                    nm: false
                },
                wed: {
                    vm: false,
                    nm: false
                },
                thr: {
                    vm: false,
                    nm: false
                },
                fri: {
                    vm: false,
                    nm: false
                },
                sat: {
                    vm: false,
                    nm: false
                }
            },
            cancelID: null,
            cancelIndex: 0,
            modal: false,
            loading: false
        };
    }

    componentWillMount() {
        this.setState({ loading: true });
        firebaseUsers.child(this.context.id).once('value', snapshot => {
            if (snapshot.val() !== null && snapshot.val().Info !== null) {
                if (snapshot.val().Info.pro) {
                    this.setState({ pro: true });
                    if (snapshot.val().Appointments !== null) {
                        Object.entries(snapshot.val().Appointments).map(appointment => {
                            console.log('Do appointment', appointment);
                            if (appointment[0] === 'Availability') {
                                this.setState({ availability: appointment[1] })
                            } else {
                                if (appointment[0] !== 'Scheduled') {
                                    this.setState(previousState => ({
                                        appointments: [...previousState.appointments, appointment]
                                    }))
                                }
                            }
                        })
                    }
                }

            }
            this.setState({ loading: false });
        }, (errorObject) => {
            console.log(errorObject);
            this.setState({ loading: false });
        });
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    prepareCancelAppointment(appointmentid, i) {
        this.setState({
            cancelID: appointmentid,
            cancelIndex: i
        });
        this.toggleModal();
    }

    cancelAppointment = () => {
        if (this.state.cancelID !== null) {
            firebaseUsers.child(this.context.id).child('Appointments').child(this.state.cancelID).update({
                canceled: true
            }).then(() => {
                let newAppointments = this.state.appointments;
                newAppointments.splice(this.state.cancelIndex, 1);
                this.setState({
                    appointments: newAppointments,
                    cancelID: null,
                    cancelIndex: 0
                });
                this.toggleModal();
                toast.success("Termin abgesagt!");
            }).catch(error => {
                if (error) {
                    toast.error(error.message);
                }
            })
        }
    }

    renderAppointments() {
        return this.state.appointments.map((appointment, i) => {
            console.log("Dooo appointment", appointment);
            if (appointment[1].date >= moment().unix() * 1000 && !appointment[1].canceled) {
                return (
                    <tr key={i} >
                        <td>{appointment[1].doctor}</td>
                        <td>{appointment[1].address}</td>
                        <td>{moment(appointment[1].date).format('MMMM Do YYYY, HH:mm')}</td>
                        <td><Button color="primary" onClick={() => this.prepareCancelAppointment(appointment[0], i)}>Termin absagen!</Button></td>
                    </tr>
                );
            }
        })
    }

    setAvailability = (day, daytime) => {
        const oldbool = this.state.availability[day][daytime];
        console.log('Do oldbool', oldbool);


        firebaseUsers.child(this.context.id).child('Appointments').child('Availability').child(day).update({
            [daytime]: !oldbool
        }).then(() => {
            this.setState(previousState => {
                let availability = { ...previousState.availability };
                availability[day][daytime] = !oldbool;
                return { availability };
            });
        }).catch(error => {
            toast.error(error.message);
        });

    }

    render() {
       if (this.state.pro && this.props.status >= 4 && this.props.status !== 8) {
            return (
                <Col md={12}>
                    <Modal
                        isOpen={this.state.modal}
                        toggle={this.toggleModal}
                        className={`modal-dialog-primary`}
                    >
                        <div className="modal__header">
                            <h4 className="bold-text  modal__title">Warning</h4>
                        </div>
                        <div className="modal__body">
                            <p>Willst du den Termin sicher löschen? Dieser Vorgang kann nicht mehr rückgängig gemacht werden!</p>
                        </div>
                        <ButtonToolbar className="modal__footer">
                            <Button onClick={this.cancelAppointment}>Fortfahren</Button>{' '}
                            <Button onClick={this.toggleModal}>Cancel</Button>
                        </ButtonToolbar>
                    </Modal>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">Zahnarzttermine</h5>
                            </div>
                            <div>
                                <h5>Bitte gib und Bescheid, wann du <b>keine</b> Zeit hast, zum Zahnarzt zu gehen.</h5>
                                <br />
                                <Table bordered size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>Montag</th>
                                            <th>Dienstag</th>
                                            <th>Mittwoch</th>
                                            <th>Donnerstag</th>
                                            <th>Freitag</th>
                                            <th>Samstag</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Button size="sm" color={this.state.availability.mon.vm ? "danger" : "success"} onClick={() => this.setAvailability('mon', 'vm')} >Vormittag</Button>
                                                <Button size="sm" color={this.state.availability.mon.nm ? "danger" : "success"} onClick={() => this.setAvailability('mon', 'nm')}>Nachmittag</Button>
                                            </td>
                                            <td>
                                                <Button size="sm" color={this.state.availability.tue.vm ? "danger" : "success"} onClick={() => this.setAvailability('tue', 'vm')}>Vormittag</Button>
                                                <Button size="sm" color={this.state.availability.tue.nm ? "danger" : "success"} onClick={() => this.setAvailability('tue', 'nm')}>Nachmittag</Button>
                                            </td>
                                            <td>
                                                <Button size="sm" color={this.state.availability.wed.vm ? "danger" : "success"} onClick={() => this.setAvailability('wed', 'vm')}>Vormittag</Button>
                                                <Button size="sm" color={this.state.availability.wed.nm ? "danger" : "success"} onClick={() => this.setAvailability('wed', 'nm')}>Nachmittag</Button>
                                            </td>
                                            <td>
                                                <Button size="sm" color={this.state.availability.thr.vm ? "danger" : "success"} onClick={() => this.setAvailability('thr', 'vm')}>Vormittag</Button>
                                                <Button size="sm" color={this.state.availability.thr.nm ? "danger" : "success"} onClick={() => this.setAvailability('thr', 'nm')}>Nachmittag</Button>
                                            </td>
                                            <td>
                                                <Button size="sm" color={this.state.availability.fri.vm ? "danger" : "success"} onClick={() => this.setAvailability('fri', 'vm')}>Vormittag</Button>
                                                <Button size="sm" color={this.state.availability.fri.nm ? "danger" : "success"} onClick={() => this.setAvailability('fri', 'nm')}>Nachmittag</Button>
                                            </td>
                                            <td>
                                                <Button size="sm" color={this.state.availability.sat.vm ? "danger" : "success"} onClick={() => this.setAvailability('sat', 'vm')}>Vormittag</Button>
                                                <Button size="sm" color={this.state.availability.sat.nm ? "danger" : "success"} onClick={() => this.setAvailability('sat', 'nm')}>Nachmittag</Button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </Table>
                                <br />
                            </div>
                            {
                                this.state.loading ?
                                    <CenterLoader /> :
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Zahnarzt</th>
                                                <th>Addresse</th>
                                                <th>Datum</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.renderAppointments()
                                            }
                                        </tbody>
                                    </Table>
                            }
                        </CardBody>
                    </Card>
                </Col >
            );
        } else {
            return (null);
        }
    }
}

AppointmentCard.contextType = UserContext;

export default AppointmentCard;
