/* eslint-disable */
import React from 'react';
import {
  Card, CardBody, Col, Button, ButtonGroup,
} from 'reactstrap';
import firebase from '../../../shared/config/firebase';
import ImageGallery from 'react-image-gallery';
import CenterLoader from '../../../shared/components/CenterLoader';

const firebaseUsers = firebase.database().ref('Users');

class ImpressionsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedImages: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true })
    firebaseUsers.child(this.props.id).child('Impressions').on('value', snapshot => {
      this.setState({ uploadedImages: [] });
      if (snapshot.val() !== undefined && snapshot.val() !== null) {
        try {
          Object.values(snapshot.val()).map((image) => {
            this.setState(previousState => ({
              uploadedImages: [...previousState.uploadedImages, { original: image, thumbnail: image }]
            }));
          })

        } catch (error) {
          console.log(error);
          this.setState({ loading: false })
        }
      }
      this.setState({ loading: false })
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({ loading: false })
    });
  }

  render() {
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Impression Bilder</h5>
            </div>
            {
              this.state.loading ?
                <CenterLoader /> :
                this.state.uploadedImages.length > 0 ?
                  <div>
                    <ImageGallery
                      items={this.state.uploadedImages}
                      showPlayButton={false}
                      showFullscreenButton={false}
                    />
                  </div>
                  :
                  "Es wurden noch keine Impression Bilder hochgeladen!"
            }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default ImpressionsCard;
