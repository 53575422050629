/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import { UserContext } from '../../App/UserContext';


class UploadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      uploadedImages: [],
      modalIsOpen: true
    };
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  }

  componentDidMount() {
    try {
      Object.entries(this.props.images).map((image) => {
        this.setState(previousState => ({
          uploadedImages: [...previousState.uploadedImages, { original: image[1], thumbnail: image[1] }]
        }));
      })
    } catch (error) {
      console.log(error);
    }
  }

  renderComment() {
    if (this.props.comment) {
      return (
        <p><b>Smilebold: </b>{this.props.comment}</p>
      )
    }
  }

  renderImage(){
    if(this.state.uploadedImages.length > 0) {
      const comment = this.renderComment();
      return(
        <div>
          {comment}
          <hr />
          <ImageGallery items={this.state.uploadedImages} showFullscreenButton={false} showPlayButton={false} />
        </div>  
      )
    } else {
      return (
        <div className="text-center">
          <h3 className="bold-text">HIER KÖNNTEN DEINE FOTOS SEIN</h3>
        </div>
      )
    }
  }

  render() {
    const aligner = parseInt(this.props.step)+1;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="bold-text">Kontrolle für Aligner {aligner}</h3>
            </div>
            {
              this.renderImage()
            }
          </CardBody>
        </Card>
      </Col>
    );
  }
}
UploadCard.contextType = UserContext;

export default UploadCard;
