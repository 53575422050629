/* eslint-disable */
import React from 'react';
import Loader from 'react-loader-spinner';

const CenterLoader = () => (
  <div className="loader">
      	<Loader color="#72347e" type="Oval"/>  
  </div>
);

export default CenterLoader;
