import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { initTawk } from '../../shared/helpers/chatWidget';
import { UserProvider, UserContext } from './UserContext';
import '../../scss/app.scss';
import Router from './Router';
import firebase from '../../shared/config/firebase';

initTawk();

const firebaseUsers = firebase.database().ref('Users');

class App extends Component {
  constructor(props) {
    super(props);
    toast.configure({
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      transition: Flip,
    });

    if (sessionStorage.getItem('userData')) {
      this.state = {
        loading: true,
        loaded: false,
        loggedIn: true,
      };
    } else {
      this.state = {
        loading: true,
        loaded: false,
        loggedIn: false,
      };
    }
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });

    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser !== null) {
        const { uid } = currentUser;
        if (uid) {
          firebaseUsers.child(uid).child('Info').on('value', (snapshot) => {
            if (snapshot.val() !== null) {
              const { email, type } = snapshot.val();
              const { updateUser } = this.context;
              updateUser({
                uid,
                email,
                type,
              });
            }
          }, (errorObject) => {
            toast.error(errorObject.message);
          });
          this.setState({
            loggedIn: true,
          });
        }
      } else {
        this.setState({
          loggedIn: false,
        });
      }
    });
  }

  render() {
    const { loaded, loading, loggedIn } = this.state;
    return (
      <BrowserRouter>
        <UserProvider>
          {!loaded
            && (
              <div className={`load${loading ? '' : ' loaded'}`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            )
          }
          <div>
            <Router loggedIn={loggedIn} />
          </div>
        </UserProvider>
      </BrowserRouter>
    );
  }
}

App.contextType = UserContext;

export default hot(module)(App);
