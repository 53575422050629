/* eslint-disable */
import React from 'react';
import {
    Card, CardBody, Col, Button, Table, ListGroup, ListGroupItem
} from 'reactstrap';
import firebase from '../../../shared/config/firebase';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import CenterLoader from '../../../shared/components/CenterLoader';

const firebaseUsers = firebase.database().ref('Users');

class AppointmentCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activated: false,
            date: "",
            doctor: "",
            address: "",
            appointments: [],
            availability: {
                mon: true,
                tue: true,
                wed: true,
                thr: true,
                fri: true,
                sat: true
            },
            loading: false
        };
    }

    componentWillMount() {
        this.setState({
            loading: true
        });
        firebaseUsers.child(this.props.id).once('value', snapshot => {
            if (snapshot.val() !== null && snapshot.val().Info !== null) {
                if (snapshot.val().Info.pro) {
                    this.setState({ activated: true });

                    if (snapshot.val().Appointments !== null) {
                        Object.entries(snapshot.val().Appointments).map(appointment => {
                            console.log('Jawoll appointments', appointment);
                            if (appointment[0] === 'Availability') {
                                this.setState({
                                    availability: appointment[1]
                                })
                            } else {
                                if (appointment[0] !== 'Scheduled') {
                                    this.setState(previousState => ({
                                        appointments: [...previousState.appointments, appointment]
                                    }))
                                }
                            }
                        })
                        this.setState({ loading: false })
                    }
                }
            }
            this.setState({ loading: false })
        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false })
        })


    }

    setAppointment = () => {
        console.log('Guck state', this.state);
        firebaseUsers.child(this.props.id).child('Appointments').push({
            date: this.state.date,
            doctor: this.state.doctor,
            address: this.state.address,
            canceled: false
        }).then((snapshot) => {
            this.setState(prevState => ({
                appointments: [...prevState.appointments, [snapshot.key, {
                    date: this.state.date,
                    doctor: this.state.doctor,
                    address: this.state.address,
                    canceled: false
                }]],
                doctor: '',
                date: '',
                address: ''
            }));
            toast.success("Termin gespeichert!");
        }).catch(error => {
            if (error) {
                toast.error(error.meassage);
            }
        });
    }

    changeDate = (e) => {
        console.log('Datetime', e.target.value);
        this.setState({ date: moment(e.target.value, 'YYYY-MM-DDTHH:mm').unix() * 1000 });
    }

    changeDoctor = (e) => {
        this.setState({ doctor: e.target.value });
    }
    changeAddress = (e) => {
        this.setState({ address: e.target.value });
    }

    cancelAppointment(appointmentid, i) {
        firebaseUsers.child(this.props.id).child('Appointments').child(appointmentid).remove().then(() => {
            let newAppointments = this.state.appointments;
            newAppointments.splice(i, 1);
            this.setState({
                appointments: newAppointments
            });
            toast.success("Termin gelöscht!");
        }).catch(error => {
            if (error) {
                toast.error(error.message);
            }
        })
    }

    renderAppointments() {
        if (this.state.appointments.length !== 0) {
            return this.state.appointments.map((appointment, i) => {
                console.log("Dooo appointment", appointment);
                //test and finish this!
                if (appointment[1].canceled) {
                    return (
                        <tr key={i} bgcolor="darkred">
                            <td>{appointment[1].doctor}</td>
                            <td>{appointment[1].address}</td>
                            <td>{moment(appointment[1].date).format('MMMM Do YYYY, HH:mm')} <br /> <b>Dieser Termin wurde abgesagt!</b></td>
                            <td><Button onClick={() => this.cancelAppointment(appointment[0], i)}>Termin löschen</Button></td>
                        </tr>
                    );
                }
                return (
                    <tr key={i} >
                        <td>{appointment[1].doctor}</td>
                        <td>{appointment[1].address}</td>
                        <td>{moment(appointment[1].date).format('MMMM Do YYYY, HH:mm')}</td>
                        <td><Button onClick={() => this.cancelAppointment(appointment[0], i)}>Termin löschen</Button></td>
                    </tr>
                );
            })
        }
    }

    renderAvailability() {
        return (
            <ListGroup>
                <p>Der Kunde ist an folgenden Tagen für einen Termin zur Verfügung:</p>
                {this.state.availability.mon ? (<ListGroupItem>Montag</ListGroupItem>) : null}
                {this.state.availability.tue ? (<ListGroupItem>Dienstag</ListGroupItem>) : null}
                {this.state.availability.wed ? (<ListGroupItem>Mittwoch</ListGroupItem>) : null}
                {this.state.availability.thr ? (<ListGroupItem>Donnerstag</ListGroupItem>) : null}
                {this.state.availability.fri ? (<ListGroupItem>Freitag</ListGroupItem>) : null}
                {this.state.availability.sat ? (<ListGroupItem>Samstag</ListGroupItem>) : null}
            </ListGroup>
        )
    }

    render() {
        if (this.state.loading) {
            return <CenterLoader />
        }
        if (this.state.activated) {
            return (
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="card__title">
                                <h5 className="bold-text">Zahnarzttermine</h5>
                            </div>
                            {
                                this.renderAvailability()
                            }
                            <form className="form">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Termin festlegen</span>
                                    <div className="form__form-group-field">
                                        <input
                                            name="date"
                                            type="datetime-local"
                                            onChange={this.changeDate}
                                            value={moment(this.state.date).format('YYYY-MM-DDTHH:mm')}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Zahnarzt</span>
                                    <div className="form__form-group-field">
                                        <input
                                            name="doctor"
                                            type="text"
                                            onChange={this.changeDoctor}
                                            value={this.state.doctor}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Adresse</span>
                                    <div className="form__form-group-field">
                                        <input
                                            name="address"
                                            type="text"
                                            onChange={this.changeAddress}
                                            value={this.state.address}
                                        />
                                    </div>
                                </div>
                                <Button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={this.setAppointment}
                                >
                                    Termin bestätigen
                            </Button>
                            </form>
                            <hr />
                            <div className="card__title">
                                <h5 className="bold-text">Termine</h5>
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Zahnarzt</th>
                                        <th>Addresse</th>
                                        <th>Datum</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.renderAppointments()
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col >
            );
        } else {
            return (null);
        }

    }
}

export default AppointmentCard;
