/* eslint-disable */
import React from 'react';
import {
  Card, CardBody, Col, Button, ButtonGroup,
} from 'reactstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import CenterLoader from '../../../shared/components/CenterLoader';

class TreatmentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      position: 'center',
    };
  }

  setPosition = position => {
    this.setState({ position });
  }

  handleChange = step => {
    this.setState({ step: ((step*(this.props.treatment.length-1))/100).toFixed() });
  };

  showImage() {
    try {
      return Object.entries(this.props.treatment[Math.abs(this.state.step)]).map((position) => {
        if (position[0] === this.state.position) {
          return <img src={position[1]} />;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  generateMarks() {
    let marks = []
    Object.keys(this.props.treatment).map((mark) => {
      if (100 / mark < Infinity) {
        marks[(100 / (this.props.treatment.length-1))* mark] = <p>{mark}.Monat</p>;
      } else {
        marks[0] = "Start"
      }
    });
    marks = Object.assign({}, marks);
    return marks;
  }

  render() {
    console.log('Do Window width', window.innerWidth);
    return (
      <Col md={12}>
        <Card>
          {
            this.props.loading ?
              <CenterLoader /> :
              <CardBody>
                <div className="card__title">
                  <h3 className="bold-text">Die Behandlung Monat für Monat</h3>
                </div>
                <div className="treatment">
                  <div className="text-center">
                    <ButtonGroup className="treatment__controller">
                      <Button onClick={() => this.setPosition("left")}>Links</Button>
                      <Button onClick={() => this.setPosition("top")}>Oben</Button>
                      <Button onClick={() => this.setPosition("center")}>Front</Button>
                      <Button onClick={() => this.setPosition("down")}>Unten</Button>
                      <Button onClick={() => this.setPosition("right")}>Rechts</Button>
                    </ButtonGroup>
                  </div>
                  <div className="text-center treatment__img">
                    {
                      this.showImage()
                    }
                  </div>
                  <div className="slider">
                    <Slider
                      dots
                      step={100 / (this.props.treatment.length-1)}
                      defaultValue={0}
                      onChange={this.handleChange}
                      trackStyle={{ backgroundColor: '#72347e', height: 10 }}
                      handleStyle={{
                        borderColor: '#72347e',
                        height: 28,
                        width: 28,
                        marginLeft: -14,
                        marginTop: -9,
                      }}
                      railStyle={{ height: 10 }}
                      dotStyle={{
                        height: 20,
                        width: 20,
                        bottom: -10,
                        marginLeft: -10,
                        borderColor: '#72347e',
                      }}
                      marks={this.generateMarks()}
                    />
                  </div>
                </div>
              </CardBody>
          }
        </Card>
      </Col>
    );
  }
}

export default TreatmentCard;
