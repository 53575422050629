/* eslint-disable */
import React from 'react';
import {
  Card, CardBody, Col, Button, ButtonGroup,
} from 'reactstrap';
import 'rc-slider/assets/index.css';
import CenterLoader from '../../../shared/components/CenterLoader';

class TreatmentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      position: 'center',
    };
  }

  setPosition = position => {
    this.setState({ position });
  }

  handleChange = step => {
    if (step !== 0) {
      const calcStep = (100 / step);
      this.setState({ step: calcStep });
    } else {
      this.setState({ step });
    }
  };

  showImage = (i) => {
    try {
      return Object.entries(this.props.treatment[i]).map((position) => {
        if (position[0] === this.state.position) {
          return <img src={position[1]} />;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <Col md={6}>
        <Card>
          {
            this.props.loading ?
              <CenterLoader /> :
              <CardBody>
                <div className="card__title">
                  <h3 className="bold-text">{this.props.userStatus== 4|| this.props.userStatus==5 ||  this.props.userStatus==6 || this.props.userStatus==7 || (this.props.data && !this.props.data.smilebold)? this.props.data.pro ? 'Dein zukünftiges Lächeln' : 'Deine Behandlung vor und nachher' : 'Dein zukünftiges Lächeln'} 😍</h3>
                </div>
                <p>Deine Behandlung wird voraussichtlich <b>{(this.props.treatment.length - 1)} Monate</b> dauern und <b>{(this.props.treatment.length - 1) * 2}   {this.props.userStatus==5 || this.props.userStatus==6 || this.props.userStatus==7 || (this.props.data && !this.props.data.smilebold) ? 'Aligner':'Schienen'}-Sets</b> beinhalten.</p>
                <br />
                <div className="treatment">
                  <div className="text-center">
                    <ButtonGroup className="treatment__controller">
                      <Button onClick={() => this.setPosition("left")}>Links</Button>
                      <Button onClick={() => this.setPosition("top")}>Oben</Button>
                      <Button onClick={() => this.setPosition("center")}>Mitte</Button>
                      <Button onClick={() => this.setPosition("down")}>Unten</Button>
                      <Button onClick={() => this.setPosition("right")}>Rechts</Button>
                    </ButtonGroup>
                  </div>
                  <div className="text-center treatment__img">
                    <h4>Vorher</h4>
                    {
                      this.showImage(0)
                    }
                    <h4>Nacher</h4>
                    {
                      this.showImage(this.props.treatment.length - 1)
                    }
                  </div>
                </div>
              </CardBody>
          }
        </Card>
      </Col>
    );
  }
}

export default TreatmentCard;
