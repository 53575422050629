/* eslint-disable */
import React from "react";
import { Row } from "reactstrap";
import GalleryCard from "./GalleryCard";
import firebase from "../../../shared/config/firebase";
import CenterLoader from "../../../shared/components/CenterLoader";
import Accordion from "react-bootstrap/Accordion";

const firebaseUsers = firebase.database().ref("Users");

class ProgressGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: null,
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    firebaseUsers
      .child(this.props.id)
      .child("Progress")
      .child("Steps")
      .once(
        "value",
        (snapshot) => {
          try {
            this.setState({
              progress: snapshot.val(),
              loading: false,
            });
          } catch (error) {
            console.log(error);
            this.setState({
              loading: false,
            });
          }
        },
        (errorObject) => {
          console.log(errorObject);
          this.setState({ loading: false });
        }
      );
  }

  renderGallery() {
    if (this.state.progress !== null) {
      return Object.entries(this.state.progress)
        .reverse()
        .map((position, index) => {
          if (!isNaN(parseInt(position[0]))) {
            if (typeof position[1].Images !== "undefined") {
              return (
                <GalleryCard
                key={index}
                  images={position[1].Images}
                  step={position[0]}
                  comment={position[1].comment}
                  id={this.props.id}
                  progress={position[1]}
                  index={index}
                  alignerLength={this.state.progress.length}
                />
              );
            } else {
              return (
                <GalleryCard
                key={index}
                  images={[]}
                  step={position[0]}
                  comment={position[1].comment}
                  id={this.props.id}
                  progress={position[1]}
                  index={index}
                  alignerLength={this.state.progress.length}
                />
              );
            }
          }
        });
    }
  }

  render() {
    if (this.state.loading) {
      return <CenterLoader />;
    } else {
      return <div>{this.renderGallery()}</div>;
    }
  }
}

export default ProgressGallery;
