/* eslint-disable */
import React, { useContext } from "react";
import { Card as OuterCard, CardBody, Col, Button } from "reactstrap";
import ImageGallery from "react-image-gallery";
import firebase from "../../../shared/config/firebase";
import { UserContext } from "../../App/UserContext";
import { toast } from "react-toastify";
import CenterLoader from "../../../shared/components/CenterLoader";
import DatePicker from "react-datepicker";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "react-datepicker/dist/react-datepicker.css";
import "../../../scss/component/accordion.scss";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";
import axios from 'axios';
import moment from "moment";

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div className="card__title">
      <h3 className="bold-text" onClick={decoratedOnClick}>
        {isCurrentEventKey ? "- " : "+ "} {children} {eventKey}
      </h3>
      {isCurrentEventKey && <hr />}
    </div>
  );
}

const firebaseUsers = firebase.database().ref("Users");

class UploadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      uploadedImages: [],
      comment: "",
      loading: false,
      allowed:
        this.props.progress && this.props.progress.allowed
          ? this.props.progress.allowed
          : "",
      staticEndDate: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    try {
      Object.entries(this.props.images).map((image) => {
        this.setState((previousState) => ({
          uploadedImages: [
            ...previousState.uploadedImages,
            { original: image[1], thumbnail: image[1] },
          ],
        }));
      });
      this.setState({
        comment: this.props.comment,
        loading: false,
        start: this.props.progress.date,
        end:
          this.props.progress.end !== undefined
            ? this.props.progress.end
            : this.props.progress.date + 1209600000,
        extra: this.props.progress.extra,
        staticEndDate:
          this.props.progress.extra > 0
            ? parseInt(this.props.progress.end) -
            parseInt(this.props.progress.extra) * 86400000
            : this.props.progress.end,
        staticExtra: this.props.progress.extra,
        allowed: this.props.progress.allowed,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }

  changeComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  changeEnd = (e) => {
    let endDate = ''
    if (this.state.staticEndDate) {
      endDate = parseInt(this.state.staticEndDate)
    } else if (this.props.progress.extra > 0) {
      endDate = parseInt(this.props.progress.end) -
        parseInt(this.props.progress.extra) * 86400000
    } else {
      endDate = this.props.progress.date + (14 * 86400000)
    }
    if (
      e.target.value != null &&
      e.target.value != "" &&
      !isNaN(e.target.value) &&
      e.target.value >= 0 &&
      endDate
    ) {
      let newStart = endDate + parseInt(e.target.value) * 86400000;
      this.setState({ extra: e.target.value });
      firebaseUsers
        .child(this.props.id)
        .child("Progress")
        .child("Steps")
        .child(this.props.step)
        .update(
          {
            end: newStart,
            extra: e.target.value,
          },
          (error) => {
            if (error) {
              toast.error(error.message);
            } else {
              toast.success("Updated Successfully!");
              this.setState({ end: newStart });
            }
          }
        );
    }
  };

  changeStartDate = (e) => {
    let dateDiff = Date.parse(e) - this.state.start;
    // alert(this.state.start);
    // alert(this.state.end)
    // console.log(
    //   "end date -----",
    //   Date.parse(e)
    // );
    // end date : -  moment(this.state.end).format("DD-MM-YYYY")

    firebaseUsers
      .child(this.props.id)
      .child("Progress")
      .child("Steps")
      .child(this.props.step)
      .update(
        {
          date: Date.parse(e),
          end: this.state.end + dateDiff,
        },
        (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            toast.success("Updated Successfully!");
          }
        }
      );
  };

  setAllowed = () => {
    this.setState((prevState) => ({
      allowed: !prevState.allowed,
    }));
    firebaseUsers
      .child(this.props.id)
      .child('Progress')
      .child('Steps')
      .child(this.props.step)
      .update(
        {
          allowed: !this.state.allowed,
        },
        (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            let tokens1;
            firebaseUsers
              .child(this.props.id)
              .child('Info')
              .on('value', async (snapshot) => {
                const data = snapshot.val();
                tokens1 = data.token;
              });
            toast.success('Wechsel freigegeben!');

            const tokens = tokens1;
            const title = "Aligner wechseln freigegeben " + (+this.props.step + 1);
            const body = this.state.comment
            axios.post('https://us-central1-smilebold.cloudfunctions.net/sendPushNotification', { tokens, title, body }).then((response) => {
              if (response.status === 200) {
                toast.success('Aligner wechseln freigegeben');
              } else {
                toast.success('Benutzer erstellt!');
                toast.error("Something went terribly wrong! Please try again!");
              }
            }).catch((error) => {
              toast.error('Something went wrong22!');
            });
          }
        }
      );
  };
  setComment = () => {
    let tokens1;
    firebaseUsers
      .child(this.props.id)
      .child('Info')
      .on('value', async (snapshot) => {
        const data = snapshot.val();
        tokens1 = data.token;
      });
    const message = {
      message: this.state.comment,
    };

    const tokens = tokens1;
    const title = "Kontrolle fur aligner " + (+this.props.step + 1);
    const body = this.state.comment
    axios.post('https://us-central1-smilebold.cloudfunctions.net/sendPushNotification', { tokens, title, body }).then((response) => {
      if (response.status === 200) {
        toast.success('User create successfully');
      } else {
        toast.success('Benutzer erstellt!');
        toast.error("Something went terribly wrong! Please try again!");
      }
    }).catch((error) => {
      toast.error('Something went wrong22!');
    });

    firebaseUsers
      .child(this.props.id)
      .child("Progress")
      .child("Steps")
      .child(this.props.step)
      .update(
        {
          comment: this.state.comment,
        },
        (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            toast.success("Kommentar gesetzt!");
          }
        }
      );
  };

  saveDate = () => {
    firebaseUsers
      .child(this.props.id)
      .child("Progress")
      .child("Steps")
      .child(this.props.step)
      .update(
        {
          date: this.state.start,
          extra: parseInt(isNaN(this.state.extra) ? 0 : this.state.extra),
        },
        (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            firebaseUsers
              .child(this.props.id)
              .child("Progress")
              .child("Steps")
              .child(this.props.step)
              .child("end")
              .remove();
            toast.success("Änderungen wurden gespeichert");
          }
        }
      );
  };

  resetAligner = () => {
    firebaseUsers
      .child(this.props.id)
      .child("Progress")
      .update(
        {
          done: parseInt(this.props.step) - 1,
        },
        (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            firebaseUsers
              .child(this.props.id)
              .child("Progress")
              .child("Steps")
              .child(this.props.step)
              .remove();
            toast.success("Aligner wurde gelöscht");
          }
        }
      );
  };

  renderAdjust() {
    return (
      <div>
        <p>
          <a
            className="btn btn-primary"
            data-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Link with href
          </a>
          <button
            className="btn btn-primary"
            type="button"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Button with data-target
          </button>
        </p>
        <div className="collapse" id="collapseExample">
          <div className="card card-body">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
            labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </div>
        <form className="form">
          <div className="form_date_field">
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Zusätzliche Tage</span>
                <div className="form__form-group-field">
                  <input
                    name="extra"
                    type="number"
                    placeholder="Zusätzliche Tage"
                    onChange={this.changeEnd}
                    value={this.state.extra}
                  />
                </div>
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Start Datum</span>
                <div className="form__form-group-field">
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={this.state.start}
                    onChange={this.changeStartDate}
                  />
                </div>
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">End Datum</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    dateFormat="dd-MM-yyyy"
                    value={moment(this.state.end).format("DD-MM-YYYY")}
                    disabled
                  //onChange={this.changeStartDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <Button className="btn btn-primary" onClick={this.saveDate}>
            Änderungen speichern
          </Button>
          <Button className="btn btn-primary" onClick={this.resetAligner}>
            Aligner zurücksetzen
          </Button>
        </form>
      </div>
    );
  }

  renderNewComment() {
    return (
      <div>
        <form className="form">
          <div className="form__form-group">
            <div className="form__form-group-field">
              <textarea
                name="comment"
                placeholder="Bitte gib mir Feedback! :)"
                onChange={this.changeComment}
                value={this.state.comment}
              />
            </div>
          </div>
          <Button className="btn btn-primary" onClick={this.setComment}>
            Kommentar schreiben
          </Button>
          <Button
            className={
              this.state.allowed ? "btn btn-success" : "btn btn-danger"
            }
            onClick={this.setAllowed}
          >
            Aligner freigeben
          </Button>
          {this.state.allowed ? null : (
            <p style={{ color: "red" }}>Nächster Aligner freigeben</p>
          )}
        </form>
      </div>
    );
  }

  render() {
    return (
      <OuterCard className="patient-desc">
        <CardBody>
          <Accordion>
            <Card>
              <Card.Header>
                <ContextAwareToggle
                  eventKey={this.props.alignerLength - this.props.index}
                >
                  Kontrolle für Aligner
                </ContextAwareToggle>
              </Card.Header>

              <Accordion.Collapse
                eventKey={this.props.alignerLength - this.props.index}
              >
                <Card.Body>
                  {this.state.loading ? (
                    <CenterLoader />
                  ) : this.state.uploadedImages.length > 0 ? (
                    <ImageGallery
                      items={this.state.uploadedImages}
                      showFullscreenButton={false}
                      showPlayButton={false}
                    />
                  ) : (
                        <div className="text-center">
                          <h3 className="bold-text">
                            HIER KÖNNTEN DEINE BILDER SEIN
                      </h3>
                        </div>
                      )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          <hr />
          {this.renderNewComment()}
          <hr />
          {this.renderAdjust()}
        </CardBody>
      </OuterCard>
    );
  }
}
UploadCard.contextType = UserContext;

export default UploadCard;
