/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { UserContext } from '../../App/UserContext';
import ToDo from './ToDo';

class StatusCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      uploadedImages: [],
      modalIsOpen: true,
      status: 0,
      checkedItems: [
        {
          id: '0',
          label: 'Starterkit bestellt und bezahlt',
          checked: true,
        },
        {
          id: '1',
          label: 'Starterkit wurde versandt',
          checked: false,
        },
        {
          id: '2',
          label: 'Starterkit Fotos wurden hochgeladen',
          checked: true,
        },
        {
          id: '3',
          label: 'Zahnabdrücke sind in der Smilebold Zentrale eingetroffen',
          checked: true,
        },
        {
          id: '4',
          label: 'Zahnabdrücke wurden bewertet',
          checked: true,
        },
        {
          id: '5',
          label: 'Smilebold Aligner werden angefertigt',
          checked: true,
        },
        {
          id: '6',
          label: 'Smilebold Aligner wurden versandt',
          checked: true,
        },
        {
          id: '7',
          label: 'Behandlung gestartet',
          checked: true,
        },
        {
          id: '8',
          label: 'Behandlung abgeschlossen',
          checked: true,
        },
      ],
    };
  }

  onChange = (e) => {
    const { checkedItems } = this.state;
    const item = e.target.name;
    const index = checkedItems.findIndex(check => check.id === item);
    checkedItems[index].checked = !checkedItems[index].checked;
    this.setState({ checkedItems: [...checkedItems] });
  };

  renderStatus = () => {
    const { status } = this.props;

    if (status === 0) {
      return(
        <div>
          <p>Deine Zahlung ist erfolgreich bei uns eingetroffen. Du erhältst eine E-Mail, sobald wir dein Starterkit versandt haben.</p>
        </div>
      )
    }
    if (status === 1) {
      return(
        <div>
          <p>Dein Starterkit ist auf dem Weg und sollte in den nächsten Tagen bei dir eintreffen.</p>
        </div>
      )
    }
    if (status === 2) {
      return(
        <div>
          <p>Deine Fotos wurden erfolgreich hochgeladen und werden bald von einem Zahnarzt begutachtet.</p>
        </div>
      )
    }
    if (status === 3) {
      return(
        <div>
          <p>Wir haben deine Zahnabdrücke erhalten und ein Zahnarzt wird deine Zahnfehlstellung genau unter die Lupe nehmen.</p>
        </div>
      )
    }
    if (status === 4) {
      return(
        <div>
          <p>Ein Zahnarzt beschäftigt sich intensiv mit deinem Fall und entscheidet, ob wir mit dir arbeiten können.</p>
        </div>
      )
    }
    if (status === 5) {
      return(
        <div>
          <p>Deine Zahlung ist erfolgreich bei uns eingetroffen! Deine Smilebold Aligner werden nun speziell für dich angefertigt und sind bald auf dem Weg zu dir.</p>
        </div>
      )
    }
    if (status === 6) {
      return(
        <div>
          <p>Wir haben deine Smilebold Aligner versandt. Sie sollten in wenigen Tagen bei dir ankommen.</p>
        </div>
      )
    }
    // if (status === 7) {
    //   return(
    //     <div>
    //       <p>Du befindest dich auf dem Weg zu deinem Traumlächeln! Weiter so 😊</p>
    //     </div>
    //   )
    // }
    if (status === 7) {
      return(
        <div>
          <p>Gratuliere! Du hast deine Behandlung erfolgreich abgeschlossen.</p>
        </div>
      )
    }
  }

  renderList = () => {
    const { checkedItems } = this.state;
    const { status } = this.props;
    return checkedItems.map(todo => (
      <ToDo
        key={todo.id}
        id={todo.id}
        label={todo.label}
        checked={status >=  todo.id ? true : false}
        onChange={this.onChange}
      />
    ))
  }

  render() {
    console.log(this.props.status);
    return (
      <Col md={6}> 
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="bold-text">Wo stehen wir? ✅</h3>
            </div>
            <div>
              {
                this.renderStatus()
              }
              <hr />
              {
                this.renderList()
              }
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
StatusCard.contextType = UserContext;

export default StatusCard;
