/* eslint-disable */
import React, { PureComponent } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Button } from 'reactstrap';
import firebase from '../../../shared/config/firebase';
import { withRouter } from 'react-router-dom';
import { UserContext } from '../../App/UserContext';
import { toast } from 'react-toastify';
import CenterLoader from '../../../shared/components/CenterLoader';

const firebaseUsers = firebase.database().ref('Users');

class LogInForm extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showPassword: false,
      password: '',
      email: '',
      loading: false,
      reset: false
    };
  }

  changePassword = (e) => {
    this.setState({ password: e.target.value });
  }

  changeEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  handleSubmit = async (e) => {
    debugger
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({
      loading: true
    })
    try {
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const id = user.user.uid;
      console.log(id);
      firebaseUsers.child(id).child('Info').on('value', snapshot => {
        debugger
        console.log(snapshot.val());
        const { email, type, first } = snapshot.val();
        this.context.updateUser({
          id,
          email,
          type,
          first,
        });
        if (type === 'admin') {
          this.props.history.push('/clients');
          console.log('Do isches drin');
        } else {
          this.props.history.push('/dashboard');
        }
      }, (errorObject) => {
        console.log(errorObject);
        this.setState({
          loading: false
        });
      });
    } catch (error) {
      toast.error(error.message);
      this.setState({
        loading: false
      });
    }
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  reset = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    })
    firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
      this.resetPassword(e);
      toast.success('Email wurde versendet');
      this.setState({
        loading: false
      })
    }).catch((error) => {
      console.log('Oanfoch error weil YOLO', error);
      toast.error(error.message);
      this.setState({
        loading: false
      })
    })
  }

  resetPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      reset: !prevState.reset,
      password: ''
    }));
  }

  render() {
    const { showPassword, email, password, loading } = this.state;

    if (!this.state.reset) {
      return (
        <div>
          {
            loading ?
              <CenterLoader />
              :
              <form className="form" onSubmit={this.handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon />
                    </div>
                    <input
                      name="name"
                      type="text"
                      placeholder="beispiel@mail.com"
                      onChange={this.changeEmail}
                      value={email}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Passwort</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <KeyVariantIcon />
                    </div>
                    <input
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Passwort"
                      onChange={this.changePassword}
                      value={password}
                    />
                    <button
                      className={`form__form-group-button${showPassword ? ' active' : ''}`}
                      onClick={e => this.showPassword(e)}
                      type="button"
                    ><EyeIcon />
                    </button>
                  </div>
                </div>
                <Button
                  className="btn btn-primary account__btn account__btn--small"
                  type="submit"
                >
                  Einloggen
              </Button>
                <Button
                  className="btn btn-secondary account__btn account__btn--small"
                  onClick={e => this.resetPassword(e)}
                >
                  Passwort anfordern
              </Button>
              </form>
          }
        </div>
      );
    } else {
      return (
        <div>
          {
            loading ?
              <CenterLoader />
              :
              <form className="form" onSubmit={this.handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon />
                    </div>
                    <input
                      name="name"
                      type="text"
                      placeholder="beispiel@mail.com"
                      onChange={this.changeEmail}
                      value={email}
                    />
                  </div>
                </div>
                <Button
                  className="btn btn-primary account__btn account__btn--small"
                  onClick={e => this.reset(e)}
                >
                  Passwort anfordern
              </Button>
                <Button
                  className="btn btn-secondary account__btn account__btn--small"
                  onClick={e => this.resetPassword(e)}
                >
                  Login
              </Button>
              </form>
          }
        </div>
      );
    }

  }
}

LogInForm.contextType = UserContext;

export default withRouter(LogInForm);
