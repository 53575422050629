import React, { PureComponent } from 'react';
import { UserContext } from '../../App/UserContext';

export default class TopbarProfile extends PureComponent {
  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    return (
      <div className="topbar__profile" />
    );
  }
}

TopbarProfile.contextType = UserContext;
