import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import BaseReactTable from './BaseReactTable';
import CenterLoader from '../../../../../shared/components/CenterLoader';
// import CustomizerTable from './ReactTableCustomizer';

const ResizableDataTable = ({ reactTableData, title }) => {
  // const [isSortable, setIsSortable] = useState(true);
  // const [withPagination, setWithPaginationTable] = useState(true);
  // const [withSearchEngine, setWithSearchEngine] = useState(true);

  // const handleClickIsSortable = () => {
  //   setIsSortable(!isSortable);
  // };

  // const handleClickWithPagination = () => {
  //   setWithPaginationTable(!withPagination);
  // };

  // const handleClickWithSearchEngine = () => {
  //   setWithSearchEngine(!withSearchEngine);
  // };


  const tableConfig = {
    isEditable: false,
    isResizable: true,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Suche nach Nachname, Vorname or email ...',
  };
  return (
    <div className="row">
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper">
              <div className="card__title">
                <h5 className="bold-text">{title}</h5>
              </div>
              {/* <CustomizerTable
            handleClickIsSortable={handleClickIsSortable}
            handleClickWithPagination={handleClickWithPagination}
            handleClickWithSearchEngine={handleClickWithSearchEngine}
            isSortable={isSortable}
            withPagination={withPagination}
            withSearchEngine={withSearchEngine}
          /> */}
            </div>
            {!reactTableData ? (
              <CenterLoader />
            ) : (
              <BaseReactTable
                columns={reactTableData.tableHeaderData}
                data={reactTableData.tableRowsData}
                tableConfig={tableConfig}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

ResizableDataTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default ResizableDataTable;
