/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import InfoCard from './components/InfoCard';
import ExplainCard from './components/ExplainCard';
import StatusCard from './components/StatusCard';
import firebase from '../../shared/config/firebase';
import { UserContext } from '../App/UserContext';
import AppointmentCard from './components/AppointmentCard';
import CenterLoader from '../../shared/components/CenterLoader';

const firebaseUsers = firebase.database().ref('Users');

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      first: null,
      loading: false,
      smilebold:null,
    }
  }

  componentWillMount() {
    this.setState({ loading: true });
    console.log(this.context.id, 'Do id');
    firebaseUsers.child(this.context.id).child('Info').once('value', snapshot => {
      try {
    
        const { status, first , smilebold } = snapshot.val();
        this.setState({ status, first,smilebold, loading: false });
      } catch (error) {
        console.log(error);
      }
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({ loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      return (<CenterLoader />)
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Willkommen {this.state.first}!</h3>
          </Col>
        </Row>
        <Row>
        {
            this.state.status !== false ? 
              <InfoCard status={this.state.status}  userStatus={this.state.status} userType={this.state.smilebold}/>
              : null
              
          }
          {
             this.state.status -1 || ((this.state.status== 6 || this.state.status== 7) && !this.state.smilebold )? null : <StatusCard status={this.state.status} />
          }
        </Row>
        <Row>
          {
            this.state.status !== -1 && (this.state.status!= 6) && (this.state.status!= 7)?
              <AppointmentCard status={this.state.status} /> :
              null
          }
        </Row>
        <Row>
          {
            this.state.status !== -1 ?
              <ExplainCard status={this.state.status} /> :
              null
          }
        </Row>
      </Container>
    );
  }
}
Dashboard.contextType = UserContext;

export default Dashboard;
