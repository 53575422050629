/* eslint-disable */
import React from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { Redirect } from "react-router-dom";
import firebase from "../../../shared/config/firebase";
import firebase2 from "firebase";
import { UserContext } from "../../App/UserContext";
import { toast } from "react-toastify";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../../../scss/component/button.scss";
import * as moment from "moment";
import { parse } from "@babel/core";

const firebaseUsers = firebase.database().ref("Users");

class InfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      value: 0,
      newAligner: false,
      startDate: '',
      endDate: '',
      extraDay :0,
      redirect: false,
      redirect2: false,
      status: false,
      tracking: null,
      loading: false,
      done: null,
      total: 0,
      pro: false,
    };
  }

  componentWillMount() {
    this.setState({ status: this.props.status, loading: true });

    firebaseUsers
      .child(this.context.id)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null && snapshot.val().Info !== null) {
          if (snapshot.val().Info.tracking !== null) {
            this.setState({
              tracking: snapshot.val().Info.tracking,
            });
          }
        }

        console.log("snapshot.val().Progress" ,snapshot.val().Progress);
        if (snapshot.val().Progress !== null) {
          console.log(snapshot.val().Progress.Steps[
            snapshot.val().Progress.done
          ]);
          this.setState({
            startDate: snapshot.val().Progress.Steps[
              snapshot.val().Progress.done
            ].date,
            endDate:snapshot.val().Progress.Steps[
              snapshot.val().Progress.done
            ].end,
            extraDay:snapshot.val().Progress.Steps[
              snapshot.val().Progress.done
            ].extra,
            redirect: false,
            loading: false,
            done: snapshot.val().Progress.done,
            total: snapshot.val().Progress.total,
            allowed: snapshot.val().Progress.Steps[snapshot.val().Progress.done]
              .allowed,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({
          loading: false,
        });
      });

    firebaseUsers.once('value', snapshot => {
      Object.entries(snapshot.val()).map(client => {
        if (client[1].Info.type === 'client') {
          this.setState(previousState => ({
            clients: [...previousState.clients, client]
          }))
        }
      })

      this.setState({
        loading: false
      });
    });

    firebaseUsers
      .child(this.context.id)
      .child("Info")
      .on(
        "value",
        (snapshot) => {
          if (snapshot.val() !== null) {
            const { pro } = snapshot.val();
            this.setState({
              pro,
              loading: false,
            });
          }
        },
        (errorObject) => {
          console.log(errorObject);
          this.setState({
            loading: false,
          });
        }
      );
  }

  startTreatment = () => {
    firebaseUsers
      .child(this.context.id)
      .child("Info")
      .update({ status: 7 }, (error) => {
        if (error) {
          toast.error(error.message);
        } else {
          firebaseUsers
            .child(this.context.id)
            .child("Progress")
            .update(
              {
                done: 0,
                Steps: {
                  0: {
                    date: firebase2.database.ServerValue.TIMESTAMP,
                    allowed: false,
                  },
                },
              },
              (error) => {
                if (error) {
                  toast.error(error.message);
                } else {
                  const date = moment().unix() * 1000;
                  this.setState({
                    status: 7,
                    startDate: date,
                  });
                  toast.success("Behandlung gestartet! 🙌");
                }
              }
            );
        }
      });
  };

  finishTreatment = () => {
    firebaseUsers
      .child(this.context.id)
      .child("Info")
      .update({ status: 8 }, (error) => {
        if (error) {
          toast.error(error.message);
        } else {
          firebaseUsers
            .child(this.context.id)
            .child("Progress")
            .update({ done: this.state.done + 1 }, (error) => {
              if (error) {
                toast.error(error.message);
              } else {
                this.setState({
                  status: 8,
                });
                toast.success("Behandlung abgeschlossen! 🙌");
                this.nextSet();
              }
            });
        }
      });
  };

  practiceAppointmentValue(slug, contextValue) {
    alert('event called' + slug)
    if (this.state.done !== null) {
      console.log(slug);
      const done = this.state.done;
      this.forceUpdate();
      firebaseUsers
        .child(contextValue)
        .child("Progress")
        .child("Steps")
        .child(done)
        .update({ practiceAppointment: slug }, (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            firebaseUsers
              .child(contextValue)
              .child("Progress")
              .child("Steps")
              .child(done)
              .update(
                {
                  practiceAppointment: slug,
                },
                (error) => {
                  if (error) {
                    toast.error(error.message);
                  }
                })
          }
        });
    }
  }

  nextSet = () => {
    const done = this.state.done;

    this.setState({
      allowed: false,
    });

    this.forceUpdate();
    firebaseUsers
      .child(this.context.id)
      .child("Progress")
      .child("Steps")
      .child(done + 1)
      .set({ date: firebase2.database.ServerValue.TIMESTAMP }, (error) => {
        if (error) {
          toast.error(error.message);
        } else {
          firebaseUsers
            .child(this.context.id)
            .child("Progress")
            .update({ done: done + 1 }, (error) => {
              if (error) {
                toast.error(error.message);
              } else {
                firebaseUsers
                  .child(this.context.id)
                  .child("Progress")
                  .child("Steps")
                  .child(done)
                  .update(
                    {
                      end: firebase2.database.ServerValue.TIMESTAMP,
                      allowed: false,
                    },
                    (error) => {
                      if (error) {
                        toast.error(error.message);
                      } else {
                        if (this.state.status === 7) {
                          this.setState({
                            upload: true,
                            alreadyUploaded: false,
                            done: done + 1,
                          });
                          toast.success(
                            "Super! Lade in ein paar Tagen Fotos für die Kontrolle hoch. 📷"
                          );
                        }
                      }
                    }
                  );
              }
            });
        }
      });
  };

  openShop = (pro) => {
    if (pro) {
      const url = "https://smilebold.co/product/pro/";
      window.open(url, "_self");
    } else {
      const url = "https://smilebold.co/product/diy/";
      window.open(url, "_self");
    }
  };

  renderStatus() {
    switch (+this.state.status) {
      case 1:
        if (this.state.tracking !== undefined) {
          return (
            <div>
              <div className="info explain">
                <p>
                  Wir haben deine Starterkit bereits verschickt und sie sollten
                  in Kurze bei dir ankommen.
                </p>
                <p>
                  Hier kannst du deine Bestellung verfolgen:{" "}
                  <a
                    href={
                      "https://www.dhl.de/en/privatkunden/pakete-empfangen/verfolgen.html?piececode=" +
                      this.state.tracking
                    }
                  >
                    {this.state.tracking}
                  </a>
                </p>
              </div>
              <hr />
              <p>Dein Paket ist bereits angekommen?</p>
              <div className="explain__btn">
                <Button
                  className="btn btn-primary"
                  onClick={() => this.setState({ redirect2: true })}
                >
                  Jetzt Fotos hochladen!
                </Button>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="info explain">
                <p>
                  Wir haben deine Starterkit bereits verschickt und sie sollten
                  in Kurze bei dir ankommen.
                </p>
                <p>Hier kannst du deine Bestellung verfolgen: Folgt in Kürze</p>
              </div>
              <hr />
              <p>Dein Paket ist bereits angekommen?</p>
              <div className="explain__btn">
                <Button
                  className="btn btn-primary"
                  onClick={() => this.setState({ redirect2: true })}
                >
                  Jetzt Fotos hochladen!
                </Button>
              </div>
            </div>
          );
        }
      case 4:
        return this.props.pro && !this.props.smilebold ? (
          <div>
            <p>
              Gratuliere! Du hast dich für den PRO qualifiziert. Das bedeutet
              gerade Zähne, nicht nur um Welten günstiger, sondern auch in
              Rekordgeschwindigkeit. Während deines gesamten Programmes wird
              dich stets ein Zahnarzt aus unserem Team begleiten und wir stehen
              dir rund um die Uhr für Fragen zu Verfügung.
            </p>
            <div className="explain__btn">
              <Button
                className="btn btn-primary"
                onClick={() => this.openShop(true)}
              >
                Jetzt ab 299,99 Euro monatlich
              </Button>
              <p>
                <i>Oder für einmalige 2.299 Euro</i>
              </p>
            </div>
            <p>
              Unter "Behandlungsplan" kannst du dir eine 3D Simulation deiner
              Zähne ansehen.
            </p>
          </div>
        ) : (
            <div>
              <p>
                Hallo, hier im Smileboard werden dir die Infos, der Controlling Ablauf und bald auch einiges mehr zu deinen
                Linardent Aligner angezeigt. Das Smileboard wird ständig von uns weiterentwickelt. Dies alles um dich bestmöglich
                zu begleiten.
              {/* Gratuliere! Du hast dich für den HOME Behandlungsplan
              qualifiziert. Das bedeutet gerade Zähne, ohne zum Zahnarztbesuch.
              Während deines gesamten Programmes wird dich stets ein Zahnarzt
              aus unserem Team begleiten und wir stehen dir rund um die Uhr für
              Fragen zu Verfügung. Somit sparst du dir nicht nur eine Menge
              Zeit, sondern auch Geld. */}
              </p>
              <br />
              <p>Falls wir schon deine Scans/Modelle von deinem Behandler erhalten haben, kannst du dir unter „Behandlungsplan“
            die 3D Simulation ansehen, wie sich deine Zähne in die gewünschte Position bewegen werden.</p>
              {/* <div className="explain__btn"> */}
              {/* <a
                className="btn btn-primary"
                onClick={() => this.openShop(false)}
              >
                Jetzt ab 139,99 Euro monatlich
              </a> */}
              {/* <p style={{ marginTop: "-15px" }}>
                <i>oder für einmalige 1.599 Euro</i>
              </p> */}
              {/* </div> */}
              {/* <p>
              Unter "Behandlungsplan" kannst du dir eine 3D Simulation deiner
              Zähne ansehen.
            </p> */}
            </div>
          );
      case 6:
        if (this.state.tracking !== undefined) {
          return (
            <div>
              <div className="info explain">
                <p>
                  {/* Wir haben deine Aligner bereits verschickt und sie sollten in
                  Kurze bei dir ankommen. */}
                  Deine Lineardent Aligner wurden angefertigt und an deinen
                  Behandler versendet. Sie werden dort in kürze eintreffen.
                </p>
                <p>
                  {/* Hier kannst du deine Bestellung verfolgen:{" "} */}
                  Sobald du die Aligner zu tragen beginnst, klicke auf den
                  Button. So können wir dich optimal unterstützen:{""}
                  <a
                    href={
                      "https://www.dhl.de/en/privatkunden/pakete-empfangen/verfolgen.html?piececode=" +
                      this.state.tracking
                    }
                  >
                    {this.state.tracking}
                  </a>
                </p>
              </div>
              <hr />
              <p>Du warst schon bei deinem Behandler in der Praxis?</p>
              <div className="explain__btn">
                <Button
                  className="btn btn-primary"
                  onClick={this.startTreatment}
                >
                  Jetzt mit der Behandlung starten!
                </Button>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="info explain">
                <p>
                  {/* Wir haben deine Aligner bereits verschickt und sie sollten in
                  Kurze bei dir ankommen. */}
                  Deine Lineardent Aligner wurden angefertigt und an deinen
                  Behandler versendet. Sie werden dort in kürze eintreffen.
                </p>
                <p>
                  {" "}
                  {/* Hier kannst du deine Bestellung verfolgen:{" "} */}
                  Sobald du die Aligner zu tragen beginnst, klicke auf den
                  Button. So können wir dich optimal unterstützen:{""}
                </p>
              </div>
              <hr />
              <p>Du warst schon bei deinem Behandler in der Praxis?</p>
              {/* Dein Paket ist bereits angekommen?</p> */}
              <div className="explain__btn">
                <Button
                  className="btn btn-primary"
                  onClick={this.startTreatment}
                >
                  Jetzt mit der Behandlung starten!
                </Button>
              </div>
            </div>
          );
        }
      case 7:
        let left = ''
        if (this.state.startDate ) {
          var startDate = moment( new Date(this.state.startDate), "DD.MM.YYYY");
          var currentDate = moment( new Date(), "DD.MM.YYYY");
          left = ( (14 + parseInt(this.state.extraDay !== undefined ? this.state.extraDay  : 0))  - currentDate.diff(startDate, "days"));
        }
        let btn = null;
        let btn2 = null;
        let btn3 = null;
        let btn4 = null;

        if (this.state.done + 1 === this.state.total) {
          if (this.state.allowed) {
            btn = (
              <div className="explain__btn">
                <Button
                  className="btn btn-primary"
                  onClick={this.finishTreatment}
                >
                  Behandlung beenden
                </Button>
              </div>
            );
          }
        } else {
          if (this.state.allowed) {
            btn = (
              <div className="explain__btn">
                <Button className="btn btn-primary" onClick={this.nextSet}>
                  Aligner wechseln
                </Button>
              </div>
            );
          }
        }

        btn2 = (
          <div className="explain__btn">
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.setState({ redirect: true });
              }}
            >
              Fotos hochladen
            </Button>
          </div>
        );

        btn3 = (
          <Button
            className="btn btn-success mb-0 "//btn-success-mannual
            onClick={() => {
              this.practiceAppointmentValue('Ja', this.context.id)
            }}
          >
            Ja
          </Button>
        );
        btn4 = (
          <Button
            className="btn btn-danger mb-0 "//btn-danger-mannual
            onClick={() => {
              this.practiceAppointmentValue('Nein', this.context.id)
            }}
          >
            Nein
          </Button>
        );
        return (
          <div>
            <p>
              Du bist bei Aligner {this.state.done + 1} von {this.state.total}
            </p>
            <div className="info__timer">
              <CircularProgressbarWithChildren
                value={left}
                maxValue={14}
                styles={buildStyles({ pathColor: "#72347e" })}
              >
                <div>
                  <p className="info__timer-number">{left}</p>
                  <p className="info__timer-description">Tage</p>
                </div>
              </CircularProgressbarWithChildren>
            </div>
            {btn2}
            {btn}
            <div className="row">
              <div className="col-md-12">
                <h5 className="page-title d-flex justify-content-center">
                  Hattest du einen Praxistermin?
                </h5>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center">
              <div className="form__button-toolbar btn-toolbar" />
              {btn3}
              {btn4}
            </div>
          </div>
        );
      case -1:
        return (
          <div>
            <p>
              Schade... Leider bist du mit unserem Programm nicht kompatibel. Du
              bekommst in den nächsten Tagen dein Geld zurücküberwiesen. Das
              Smilebold Team wünscht dir das Beste und empfiehlt dir, dich von
              einem Zahnarzt beraten zu lassen. 🍀
            </p>
          </div>
        );
      default:
        return (
          <p>
            Das ist dein persönliches Dashboard. Hier kannst du deinen
            Behandlungsplan einsehen und wir können gemeinsam deinen Fortschritt
            verfolgen. Verwende das Dashboard um uns mitzuteilen, wann du
            beginnst deine Aligner zu tragen und bei jedem Alignerwechsel. Bitte
            vergiss nicht regelmäßig deine E- Mails (auch den Spamordner) zu
            checken, da wir dich dort über Updates in deinem Dashboard
            informieren.
          </p>
        );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/progress" />;
    }

    if (this.state.redirect2) {
      return <Redirect to="/upload" />;
    }
    <card />;
    return (
      <Col md={this.props.userStatus == 6 || !this.props.smilebold ? 12 : 6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="bold-text">
                {/* {this.props.userStatus == 6 || this.props.userStatus == 7 */}
                  Willkommen im Smileboard!
                  {/* : "Willkommen bei Smilebold!"}{" "} */}
                🙋‍♂️
              </h3>
            </div>
            <div className="info explain">{this.renderStatus()}</div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
InfoCard.contextType = UserContext;

export default InfoCard;
