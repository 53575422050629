/* eslint-disable */
import React from 'react';
import {
  Card, CardBody, Col, Button, ButtonGroup,
} from 'reactstrap';
import { toast } from 'react-toastify';
import CenterLoader from '../../../shared/components/CenterLoader';

import firebase from '../../../shared/config/firebase';

const firebaseUsers = firebase.database().ref('Users');

class ClientsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first: "",
      last: "",
      status: "",
      email: "",
      tel: "",
      street: "",
      zip: "",
      city: "",
      ID: "",
      country: "",
      loading: false,
      smilebold: true
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    firebaseUsers.child(this.props.id).child('Info').on('value', snapshot => {
      const user = snapshot.val();
      this.setState({
        first: user.first,
        last: user.last,
        status: user.status,
        email: user.email,
        tel: user.tel,
        street: user.street,
        zip: user.zip,
        city: user.city,
        ID: user.ID,
        country: user.country,
        smilebold: user.smilebold,
        loading: false
      })
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({
        loading: false
      })
    });
  }

  changeFirst = (e) => {
    this.setState({ first: e.target.value });
  }

  changeLast = (e) => {
    this.setState({ last: e.target.value });
  }

  changeStatus = (e) => {
    this.setState({ status: e.target.value });
  }

  changeEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  changeTel = (e) => {
    this.setState({ tel: e.target.value });
  }

  changeStreet = (e) => {
    this.setState({ street: e.target.value });
  }

  changeZip = (e) => {
    this.setState({ zip: e.target.value });
  }

  changeCity = (e) => {
    this.setState({ city: e.target.value });
  }

  changeID = (e) => {
    this.setState({ ID: e.target.value });
  }

  changeCountry = (e) => {
    this.setState({ country: e.target.value });
  }

  setSmilebold = () => {
    this.setState(prevState => ({
      smilebold: !prevState.smilebold
    }));
    this.setState({ status: 6 });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { first, last, email, status, tel, street, zip, country, ID, city, smilebold } = this.state;
    try {
      const userInfo = {
        email,
        tel,
        first,
        last,
        street,
        zip,
        city,
        ID,
        country,
        status,
        smilebold
      }
      firebaseUsers.child(this.props.id).child('Info').update(userInfo, (error) => {
        if (error) {
          toast.error(error.message);
        } else {
          toast.success("Benutzer bearbeitet!");
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  render() {
    const { first, last, email, status, tel, street, zip, country, ID, city } = this.state;
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Benutzer bearbeiten</h5>
              <Button
                className="btn btn-primary"
                onClick={() => this.setSmilebold()}
              >
                {
                  this.state.smilebold ? 'Smilebold' : 'Lineardent'
                }
              </Button>
            </div>
            {
              this.state.loading ?
                <CenterLoader /> :
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Vorname</span>
                      <div className="form__form-group-field">
                        <input
                          name="first"
                          type="text"
                          placeholder="Vorname"
                          onChange={this.changeFirst}
                          value={first}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Nachname</span>
                      <div className="form__form-group-field">
                        <input
                          name="last"
                          placeholder="Nachname"
                          onChange={this.changeLast}
                          value={last}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Telefon</span>
                      <div className="form__form-group-field">
                        <input
                          name="tel"
                          type="text"
                          placeholder="+43 67729383232"
                          onChange={this.changeTel}
                          value={tel}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Email</span>
                      <div className="form__form-group-field">
                        <input
                          name="email"
                          type="text"
                          placeholder="beispiel@mail.com"
                          onChange={this.changeEmail}
                          value={email}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Status</span>
                      <div className="form__form-group-field">
                        <input
                          name="status"
                          placeholder="Status"
                          type="text"
                          onChange={this.changeStatus}
                          value={status}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">ID</span>
                      <div className="form__form-group-field">
                        <input
                          name="ID"
                          type="text"
                          placeholder="ID"
                          onChange={this.changeID}
                          value={ID}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Straße</span>
                      <div className="form__form-group-field">
                        <input
                          name="street"
                          type="text"
                          placeholder="Straße"
                          onChange={this.changeStreet}
                          value={street}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Postleitzahl</span>
                      <div className="form__form-group-field">
                        <input
                          name="zip"
                          placeholder="Postleitzahl"
                          type="text"
                          onChange={this.changeZip}
                          value={zip}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Stadt</span>
                      <div className="form__form-group-field">
                        <input
                          name="city"
                          type="text"
                          placeholder="Stadt"
                          onChange={this.changeCity}
                          value={city}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Land</span>
                      <div className="form__form-group-field">
                        <input
                          name="country"
                          type="text"
                          placeholder="Land"
                          onChange={this.changeCountry}
                          value={country}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    className="btn btn-primary account__btn account__btn--small"
                    type="submit"
                  >
                    Ändern
              </Button>
                </form>
            }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default ClientsCard;
