/* eslint-disable */
import React, { PureComponent } from "react";
import { Col, Container, Row } from "reactstrap";
import ImpressionsCard from "./components/ImpressionsCard";
import InfoCard from "./components/InfoCard";
import TreatmentCard from "./components/TreatmentCard";
import ProgressGallery from "./components/ProgressGallery";
import AppointmentCard from "./components/AppointmentCard";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
class ProfilePage extends PureComponent {
  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Behandlungsplan</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
           <ProgressGallery id={this.props.location.state.id} />
          </Col>
        </Row>
        <Row>
          <InfoCard id={this.props.location.state.id} />
          <AppointmentCard id={this.props.location.state.id} />
          <ImpressionsCard id={this.props.location.state.id} />
          <TreatmentCard id={this.props.location.state.id} />
        </Row>
      </Container>
    );
  }
}

export default ProfilePage;
