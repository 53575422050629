/* eslint-disable */
import React from 'react';
import {
  Card,
  Button,
  ButtonToolbar,
  CardBody,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';
import firebase from '../../../shared/config/firebase';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import CenterLoader from '../../../shared/components/CenterLoader';
import ResizableDataTable from '../Tables/ResizableTable/components/ResizableTable';
// import CreateTableData from '../Tables/CreateData';
//import ResizableDataTable from './Tables/ResizableTable/components/ResizableTable';

const firebaseUsers = firebase.database().ref("Users");

class ClientsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      dropdownOpen: [],
      modal: false,
      currentClient: -1,
      tracking: "",
      loading: false,
      reactTableData: {},
    };
    // this.setState({reactTableData : CreateTableData()});
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillMount() {
    // this.setState({ reactTableData: () => CreateTableData() });
    this.setState({ loading: true });
    firebaseUsers.once(
      "value",
      (snapshot) => {
        let {clients, dropdownOpen} = this.state;
        Object.entries(snapshot.val()).map((client) => {
          if (client[1].Info.type === "client") {
            clients=  [...clients, client];
              dropdownOpen= [...dropdownOpen, false];
            // this.setState((previousState) => ({
            //   clients: [...previousState.clients, client],
            //   dropdownOpen: [...previousState.dropdownOpen, false],
            // }));
          }
        });
        this.setState({
          loading: false,
          clients,
          dropdownOpen
        });
      },
      (errorObject) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  componentDidUpdate(prevProps,prevState){
    if (prevState.clients !== this.state.clients){
      this.setTableData();
    }
  }

  setTableData
  (){
    if(!this.state.clients.length){
      return 
    }
    const data = [];
    const columns = [
      {
        Header: 'ID',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Vorname',
        accessor: 'vorname',
      },
      {
        Header: 'Nachname',
        accessor: 'nachname',
      },
      {
        Header: 'Tracking',
        accessor: 'tracking',
        disableGlobalFilter: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
      },
      {
        Header: 'Aktueller Aligner',
        accessor: 'aktuellerAligner',
        disableGlobalFilter: true,
      },
      {
        Header: 'Anzahl Aligner',
        accessor: 'anzahlAlig',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Profile',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ];
    this.state.clients &&  this.state.clients.map((client, i) => {
      data.push({
        id: client[1].Info.ID,
        type: client[1].Info.smilebold ? "S" : "L",
        email: client[1].Info.email,
        vorname: client[1].Info.first,
        nachname: client[1].Info.last,
        tracking: client[1].Info.tracking,
        statusColor: client[1].Info.status,
        status: //client[1].Info.status,
         (
           <>
           {/* {`@@@@${this.state.dropdownOpen[i]}`} */}
            <Dropdown
              isOpen={this.state.dropdownOpen[i]}
              toggle={() => this.toggle(i)}
            >
              <DropdownToggle caret>
                <b>Status {client[1].Info.status}</b>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.setStatus(client[0], 0)}>
                  Status 0
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 1)}>
                  Status 1
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 2)}>
                  Status 2
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 3)}>
                  Status 3
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 4)}>
                  Status 4
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 5)}>
                  Status 5
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 6)}>
                  Status 6
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 7)}>
                  Status 7
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], 8)}>
                  Status 8
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => this.setStatus(client[0], -1)}>
                  Status -1
                </DropdownItem>
                <DropdownItem onClick={() => this.setStatus(client[0], -2)}>
                  Status -2
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        ),
        aktuellerAligner: client[1].Progress ? client[1].Progress.done : "",
        anzahlAlig: client[1].Progress ? client[1].Progress.total : "",
        action: (
          <Button onClick={() => this.toProfile(client[0])}>Profil</Button>
        ),
      });
    });
    this.setState({reactTableData:{tableHeaderData: columns, tableRowsData: data}})
  }

  toggle(i) {
    const newDropdownOpen = this.state.dropdownOpen.slice();
    newDropdownOpen[i] = !newDropdownOpen[i];
    // this.setState({ dropdownOpen: newDropdownOpen });
    this.setState({ dropdownOpen: newDropdownOpen  }, this.setTableData);
  }

  toggleModal() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  setStatus(id, status) {
    firebaseUsers
      .child(id)
      .child("Info")
      .update({ status }, (error) => {
        if (error) {
          toast.error(error.message);
        } else {
          toast.success("Status aktualisiert!");
          Object.values(this.state.clients).map((client, index) => {
            if (client[0] === id) {
              let newClients = this.state.clients;
              newClients[index][1].Info.status = status;
              this.setState({
                clients: newClients,
              },
              this.setTableData);
            }
          });
        }
      });
    this.setState({
      currentClient: id,
    });
    if (status === 1 || status === 6) {
      this.toggleModal();
    }
  }

  changeTracking = (e) => {
    this.setState({ tracking: e.target.value });
  };

  setTracking() {
    const tracking = this.state.tracking;
    firebaseUsers
      .child(this.state.currentClient)
      .child("Info")
      .update({ tracking }, (error) => {
        if (error) {
          toast.error(error.message);
        } else {
          toast.success("Tracking gesetzt!");
          Object.values(this.state.clients).map((client, index) => {
            console.log("Do mueses inni gien", client);
            if (client[0] === this.state.currentClient) {
              let newClients = this.state.clients;
              newClients[index][1].Info.tracking = tracking;
              console.log("NewClients", newClients);
              this.setState({
                clients: newClients,
              });
            }
          });
        }
      });
    this.toggleModal();
  }

  toProfile(id) {
    this.props.history.push({
      pathname: "/profile",
      state: { id },
    });
  }

  render() {
    return (
      <Col md={12} lg={12}>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={`modal-dialog-primary`}
          onSubmit={() => this.setTracking()}
        >
          <div className="modal__header">
            <h4 className="bold-text  modal__title">Tracking</h4>
          </div>
          <div className="modal__body">
            <form className="form">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Tracking Code"
                    onChange={this.changeTracking}
                  />
                </div>
              </div>
              <ButtonToolbar className="modal__footer">
                <Button onClick={this.toggleModal}>Cancel</Button>{" "}
                <Button type="submit">Speichern</Button>
              </ButtonToolbar>
            </form>
          </div>
        </Modal>
        {this.state.loading ? (
          <CenterLoader />
        ) : (
          <ResizableDataTable reactTableData={this.state.reactTableData} title='Kundenliste'/>
        )}
      </Col>
    );
  }
}

export default withRouter(ClientsTable);
