/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import { UserContext } from '../../App/UserContext';
import firebase from '../../../shared/config/firebase';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  logoutUser = () => {
    firebase.auth().signOut().then(() => {
      console.log('Sign out successfull');
      sessionStorage.clear();
    }).catch(() => {
      console.log('Sign out failed!');
    })
    const { onClick } = this.props;
    onClick();
  }

  render() {
    if (this.context.type === 'admin') {
      return (
        <div className="sidebar_content">
          <ul className="sidebar__block">
            <div>
              <SidebarLink title="Kundenverwaltung" icon="users" route="/clients" onClick={this.hideSidebar} />
            </div>
            <div>
              <SidebarLink title="Kundenfortschritt" icon="users" route="/clientProgress" onClick={this.hideSidebar} />
            </div>
          </ul>
          <ul className="sidebar__block">
            <SidebarLink title="Ausloggen" icon="exit" route="/log_in" onClick={this.logoutUser} />
          </ul>
        </div>
      )
    } else {
      return (
        <div className="sidebar__content">
          <ul className="sidebar__block">
            <SidebarLink title="Überblick" icon="home" route="/dashboard" onClick={this.hideSidebar} />
            {
              this.context.status === 7 ?
                <SidebarLink title="Fortschritt Upload" icon="rocket" route="/progress" onClick={this.hideSidebar} /> :
                null
            }
            {
              (this.context.status > 0 && this.context.status < 4) ?
                <SidebarLink title="Starterkit Upload" icon="upload" route="/upload" onClick={this.hideSidebar} /> :
                null
            }
            {
              this.context.status >= 4 ?
                <SidebarLink title="Behandlungsplan" icon="smile" route="/treatment" onClick={this.hideSidebar} /> :
                null
            }
          </ul>
          <ul className="sidebar__block">
            <SidebarLink title="Ausloggen" icon="exit" route="/log_in" onClick={this.logoutUser} />
          </ul>
        </div>

      );
    }
  }
}
SidebarContent.contextType = UserContext;
/* <div className="sidebar__content">
          <ul className="sidebar__block">
            {this.context.type === 'client' &&
              <div>
                <SidebarLink title="Überblick" icon="home" route="/dashboard" onClick={this.hideSidebar} />
                <SidebarLink title="Fortschritt Upload" icon="rocket" route="/progress" onClick={this.hideSidebar} />
                {this.context.status < 2 &&
                  <SidebarLink title="Starterkit Upload" icon="upload" route="/upload" onClick={this.hideSidebar} />
                }
                <SidebarLink title="Behandlungsplan" icon="smile" route="/treatment" onClick={this.hideSidebar} />
              </div>
            }
          </ul>
          <ul className="sidebar__block">
            <SidebarLink title="Ausloggen" icon="exit" route="/log_in" onClick={this.logoutUser} />
          </ul>
        </div> */

export default SidebarContent;
