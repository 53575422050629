/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import TreatmentCard from './components/TreatmentCard';
import ExplainCard from './components/ExplainCard';
import ComparisonCard from './components/ComparisonCard';
import firebase from '../../shared/config/firebase';
import { UserContext } from '../App/UserContext';

const firebaseUsers = firebase.database().ref('Users');

class TreatmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pro: false,
      treatment: [{}, {}],
      loading: false,
      loading2: false,
      status:null,
      data:null,
    };
  }

  componentWillMount() {
    this.setState({ loading: true })
    firebaseUsers.child(this.context.id).child('Info').on('value', snapshot => {
      if (snapshot.val() !== null) {
        const { pro, status } = snapshot.val();
        this.setState({
          pro,
          loading: false,
          status,
          data:snapshot.val(),
        })
      }
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({
        loading: false
      })
    });
    firebaseUsers.child(this.context.id).child('Treatment').on('value', snapshot => {
      if (snapshot.val() !== null) {
        this.setState({
          treatment: snapshot.val(),
          loading2: false
        })
      }
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({
        loading2: false
      })
    });
  }

  render () {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Behandlungsplan</h3>
          </Col>
        </Row>
        <Row>
          <ExplainCard status={this.state.status} data={this.state.data} userStatus={this.state.status} pro={this.state.pro} loading={this.state.loading} months={(this.state.treatment.length - 1)} sets={(this.state.treatment.length - 1) * 2}/>
          <ComparisonCard treatment={this.state.treatment} data={this.state.data} userStatus={this.state.status} loading={this.state.loading}/>
        </Row>
        <Row>
          <TreatmentCard treatment={this.state.treatment} loading={this.state.loading}/>
        </Row>
      </Container>
    );
  }
}

TreatmentPage.contextType = UserContext;

export default TreatmentPage;
