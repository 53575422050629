/* eslint-disable */
import React, { createContext } from 'react';
import firebase from '../../shared/config/firebase';

const firebaseUsers = firebase.database().ref('Users');

export const UserContext = createContext({
  id: -1,
  type: '',
  email: '',

  updateUser: () => { },
  resetUser: () => { },
});

export class UserProvider extends React.Component {

  constructor() {
    super();

    if (sessionStorage.getItem('userData')) {
      this.state = {
        id: JSON.parse(sessionStorage.getItem('userData')).id,
        type: JSON.parse(sessionStorage.getItem('userData')).type,
        email: JSON.parse(sessionStorage.getItem('userData')).email,
        first: JSON.parse(sessionStorage.getItem('userData')).first,
        status: JSON.parse(sessionStorage.getItem('userData')).status,
        updateUser: this.updateUser,
        resetUser: this.resetUser,
      };
    } else {
      this.state = {
        id: -1,
        type: '',
        email: '',
        first: '',
        status: '',
        type: '',
        pro: '',
        updateUser: this.updateUser,
        resetUser: this.resetUser,
      };
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser !== null) {
        const { uid } = currentUser;
        if (uid) {
          firebaseUsers.child(uid).child('Info').on('value', (snapshot) => {
            if (snapshot.val() !== null) {
              const { email, type, first, status, pro } = snapshot.val();
              sessionStorage.setItem('userData', JSON.stringify({
                id: uid,
                email,
                first,
                status,
                type,
                pro
              }));
              this.setState({
                id: uid,
                email,
                first,
                status,
                type,
                pro
              });
            }
          }, (errorObject) => {
            console.log(errorObject);
          });
        }
      }
    });
  }

  updateUser = newUser => {
    this.setState({
      ...newUser,
    });
  };

  resetUser = () => {
    this.setState({
      id: -1,
      type: '',
      email: '',
    });
  }



  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;