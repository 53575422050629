/* eslint-disable */
import React from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import { UserContext } from "../../App/UserContext";
import CenterLoader from "../../../shared/components/CenterLoader";

class ExplainCard extends React.Component {
  openShop = (pro) => {
    if (pro) {
      const url = "https://smilebold.co/product/pro/";
      window.open(url, "_self");
    } else {
      const url = "https://smilebold.co/product/diy/";
      window.open(url, "_self");
    }
  };

  render() {
    console.log(this.props.pro);
    return (
      <Col md={6}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="bold-text">Es kann losgehen!  🎉</h3>
            </div>
            <div className="explain">
              {this.props.loading ? (
                <CenterLoader />
              ) : this.props.pro ? (
                this.props.userStatus == 4 || this.props.userStatus == 5 || this.props.userStatus == 6 || this.props.userStatus == 7 ||
                (this.props.data && !this.props.data.smilebold) ? (
                  <div>
                    <p>
                      Wir haben deinen Behandlungsplan erstellt. Hier siehst du,
                      wie sich Monat für Monat deine Zähne in die geplante
                      Position bewegen. Auf der rechten Seite siehst du das
                      Vorher-/Nachher Bild auf einem Blick.
                    </p>
                    <p>
                      Deine Behandlung wird voraussichtlich{" "}
                      <b>{this.props.months} Monate</b> dauern und{" "}
                      <b>{this.props.sets} Lineardent Aligner Sets</b>{" "}
                      beeinhalten.
                    </p>
                    <p>
                      Die genaue Anzahl der Lineardent Aligner ist im Begleitbogen vermerkt und
                      liegt deinem Behandler vor.
                    </p>
                    {/* <div className="explain__btn">
                  <Button
                    className="btn btn-primary"
                    onClick={() => this.openShop(true)}
                  >
                    Jetzt ab 199,99 Euro monatlich
                  </Button>
                  <p>
                    <i>Oder für einmalige 2.299 Euro</i>
                  </p>
                </div> */}
                    <p>
                      Weiter unten findest du noch einen detaillierten
                      Behandlungsverlauf 👇
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      Gratuliere! Du hast dich für den PRO Behandlungsplan
                      qualifiziert. Das bedeutet gerade Zähne, nicht nur um
                      Welten günstiger, sondern auch in Rekordgeschwindigkeit.
                      Während deines gesamten Programmes wird dich stets ein
                      Zahnarzt aus unserem Team begleiten und wir stehen dir
                      rund um die Uhr für Fragen zu Verfügung.
                    </p>
                    <p>
                      Deine Behandlung wird voraussichtlich{" "}
                      <b>{this.props.months} Monate</b> dauern und{" "}
                      <b>{this.props.sets} Smilebold Aligner Sets</b>{" "}
                      beinhalten. Zusätzlich sind bis zu 3 Termine mit unserem
                      Partnerzahnarzt inkludiert.
                    </p>
                    {this.props.status > 4 ? null : (
                      <div>
                        <p>
                          Jetzt bezahlen und sofort mit deiner Behandlung
                          beginnen!
                        </p>
                        <div className="explain__btn">
                          <Button
                            className="btn btn-primary"
                            onClick={() => this.openShop(true)}
                          >
                            Jetzt ab 299,99 Euro monatlich
                          </Button>
                          <p>
                            <i>oder für einmalige 2.299 Euro</i>
                          </p>
                        </div>
                      </div>
                    )}
                    <p>
                      Weiter unten findest du noch einen detaillierten
                      Behandlungsverlauf 👇
                    </p>
                  </div>
                )
              ) : this.props.userStatus == 4 || this.props.userStatus == 5 ||this.props.userStatus == 6|| this.props.userStatus == 7 ||
              (this.props.data && !this.props.data.smilebold) ? (
              <div>
                <p>
                  Wir haben deinen Behandlungsplan erstellt. Hier siehst du,
                  wie sich Monat für Monat deine Zähne in die geplante
                  Position bewegen. Auf der rechten Seite siehst du das
                  Vorher-/Nachher Bild auf einem Blick.
                </p>
                <p>
                  Deine Behandlung wird voraussichtlich{" "}
                  <b>{this.props.months} Monate</b> dauern und{" "}
                  <b>{this.props.sets} Lineardent Aligner Sets</b>{" "}
                  beeinhalten.
                </p>
                <p>
                      Die genaue Anzahl der Lineardent Aligner ist im Begleitbogen vermerkt und
                      liegt deinem Behandler vor.
                </p>
                <p>
                  Weiter unten findest du noch einen detaillierten
                  Behandlungsverlauf 👇
                </p>
              </div>


            ):(
                <div>
                  <p>
                    Gratuliere! Du hast dich für den HOME Behandlungsplan
                    qualifiziert. Das bedeutet gerade Zähne, ohne zum
                    Zahnarztbesuch. Während deines gesamten Programmes wird dich
                    stets ein Zahnarzt aus unserem Team begleiten und wir stehen
                    dir rund um die Uhr für Fragen zu Verfügung. Somit sparst du
                    dir nicht nur eine Menge Zeit, sondern auch Geld.
                  </p>
                  <p>
                    Deine Behandlung wird voraussichtlich{" "}
                    <b>{this.props.months} Monate</b> dauern und{" "}
                    <b>{this.props.sets} Smilebold Aligner Sets</b> beinhalten.
                  </p>
                  {this.props.status > 4 ? null : (
                    <div>
                      <p>
                        Jetzt bezahlen und sofort mit deiner Behandlung
                        beginnen!
                      </p>
                      <div className="explain__btn">
                        <Button
                          className="btn btn-primary"
                          onClick={() => this.openShop(false)}
                        >
                          Jetzt ab 139,99 Euro monatlich
                        </Button>
                        <p>
                          <i>oder für einmalige 1.599 Euro</i>
                        </p>
                      </div>
                    </div>
                  )}
                  <p>
                    Weiter unten findest du noch einen detaillierten
                    Behandlungsverlauf 👇
                  </p>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
ExplainCard.contextType = UserContext;

export default ExplainCard;
