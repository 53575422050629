/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import firebase from '../../../shared/config/firebase';
import firebase2 from 'firebase';
import { UserContext } from '../../App/UserContext';
import { toast } from 'react-toastify';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import * as moment from 'moment';

const firebaseUsers = firebase.database().ref('Users');

class ExplainCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      newAligner: false,
      startDate: 0,
      redirect: false,
      redirect2: false,
      status: false,
      tracking: null,
      loading: false,
      done: 0,
      total: 0,
      pro: false
    };
  }

  componentWillMount() {
    this.setState({ status: this.props.status, loading: true })

    firebaseUsers.child(this.context.id).once('value').then(snapshot => {
      if (snapshot.val() !== null && snapshot.val().Info !== null) {
        if (snapshot.val().Info.tracking !== null) {
          this.setState({
            tracking: snapshot.val().Info.tracking
          })
        }
      }

      if (snapshot.val().Progress !== null) {

        this.setState({
          startDate: snapshot.val().Progress.Steps[snapshot.val().Progress.done].date,
          redirect: false,
          loading: false,
          done: snapshot.val().Progress.done,
          total: snapshot.val().Progress.total,
          allowed: snapshot.val().Progress.Steps[snapshot.val().Progress.done].allowed
        })
      }
    }).catch(error => {
      toast.error(error.message);
      this.setState({
        loading: false
      });
    })

    firebaseUsers.child(this.context.id).child('Info').on('value', snapshot => {
      if (snapshot.val() !== null) {
        const { pro } = snapshot.val();
        this.setState({
          pro,
          loading: false
        })
      }
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({
        loading: false
      })
    });
  }

  startTreatment = () => {
    firebaseUsers.child(this.context.id).child('Info').update({ status: 7 }, (error) => {
      if (error) {
        toast.error(error.message);
      } else {
        firebaseUsers.child(this.context.id).child('Progress').update({done: 0, Steps: {0: {date: firebase2.database.ServerValue.TIMESTAMP, allowed: false}}}, (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            const date = moment().unix() * 1000;
            this.setState({
              status: 7,
              startDate: date
            })
            toast.success("Behandlung gestartet! 🙌");
          }
        });
      }
    });
  }

  finishTreatment = () => {
    firebaseUsers.child(this.context.id).child('Info').update({ status: 8 }, error => {
      if (error) {
        toast.error(error.message);
      } else {
        firebaseUsers.child(this.context.id).child('Progress').update({ done: this.state.done + 1 }, error => {
          if (error) {
            toast.error(error.message);
          } else {
            this.setState({
              status: 8,
            });
            toast.success("Behandlung abgeschlossen! 🙌");
            this.nextSet();
          }
        })
      }
    })
  }

  nextSet = () => {
    const done = this.state.done;

    this.setState({
      allowed: false
    })

    this.forceUpdate();

    firebaseUsers.child(this.context.id).child('Progress').child('Steps').child(done + 1).set({ date: firebase2.database.ServerValue.TIMESTAMP }, (error) => {
      if (error) {
        toast.error(error.message);
      } else {
        firebaseUsers.child(this.context.id).child('Progress').update({ done: done + 1 }, (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            firebaseUsers.child(this.context.id).child('Progress').child('Steps').child(done).update({ end: firebase2.database.ServerValue.TIMESTAMP, allowed: false }, (error) => {
              if (error) {
                toast.error(error.message);
              } else {
                if (this.state.status === 7) {      
                  this.setState({
                    upload: true,
                    alreadyUploaded: false,
                    done: done + 1
                  })
                  toast.success("Super! Lade in ein paar Tagen Fotos für die Kontrolle hoch. 📷");
                }
              }
            });
          }
        });
      }
    });
  }

  openShop = pro => {
    if (pro) {
      const url = 'https://smilebold.co/product/pro/';
      window.open(url, '_self');
    } else {
      const url = 'https://smilebold.co/product/diy/';
      window.open(url, '_self');
    }
  }

  renderStatus() {

    switch (this.state.status) {
      case  6:
      case  7:
        return (
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h3 className="bold-text">So können wir dich optimal unterstützen 🙏</h3>
                </div>
                <div className="info explain">
                  <p> Setze deine neuen Aligner ein, mach gleich ein Foto und lade dieses hoch. So können wir gleich sehen wie der Aligner passt. Das ist für uns sehr hilfreich. </p>
                  <p> Am besten setzt du den Aligner unter Tag ein, dass du dich schon mal daran gewöhnen kannst. Es ist ganz normal, dass der erste Aligner drückt. Das legt sich.  </p>
                  <p> Die Chewies: <b>Um das Einsitzen der Aligner zu erleichtern, kannst du die Chewies benutzen.</b> Nach dem Einsetzen des Aligners auf die obere und untere Zahnreihe für einige Minuten auf eines dieser Chewie-Röllchen beißen. Der Vorgang des Beißens sollte von jedem Zahn mindestens einmal durchgeführt wird. Der Vorgang unterstützt das Einsitzen des Aligners.</p>
                  <p> Erst nachdem wir den Sitz deiner Aligner kontrolliert und kommentiert haben, schalten wir dir den neuen Aligner frei.  </p>
                  <p> Erinnere dich auf „Aligner wechseln“ zu klicken. Der Button erscheint erst nachdem wir die Kontrolle abgeschlossen haben.  </p>
                  <p> Bei Fragen sind wir im Chat für dich da. Gib dazu bitte immer deine Mailadresse an.  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          
        )
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/progress' />;
    }

    if (this.state.redirect2) {
      return <Redirect to='/upload' />;
    }

    return (
      <div>
      {
        this.renderStatus()
      }
      </div>
    );
  }
}
ExplainCard.contextType = UserContext;

export default ExplainCard;
