/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col, Row, ListGroup, ListGroupItem } from 'reactstrap';

class StatusCard extends React.Component {

    render() {
        console.log(this.props.status);
        return (
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h3 className="bold-text">Status Legende</h3>
                        </div>
                        <Row>
                            <Col>
                                <ListGroup>
                                    <ListGroupItem><b>Status 0:</b> Bestellung wurde erhalten, Abdruckset muss versendet werden</ListGroupItem>
                                    <ListGroupItem><b>Status 1:</b> Abdruckset wurde versendet, Tracking kann angegeben werden</ListGroupItem>
                                    <ListGroupItem style={{background: "#ff8000"}}><b>Status 2:</b> Die Bilder vom Patienten wurden hochgeladen</ListGroupItem>
                                    <ListGroupItem style={{background: "#edd100"}}><b>Status 3:</b> Abdruckset ist in der Smilebold Zentrale eingetroffen</ListGroupItem>
                                    <ListGroupItem style={{background: "red"}}><b>Status 4:</b> Abdrücke wurden bewertet und vom Zahnarzt überprüft</ListGroupItem>
                                    <ListGroupItem><b>Status 5:</b> Zahlung wurde erhalten und Aligner werden produziert</ListGroupItem>
                                </ListGroup>
                            </Col>
                            <Col>
                                <ListGroup>
                                    <ListGroupItem style={{background: "#8A2BE2"}}><b>Status 6:</b> Aligner wurden verschickt, Tracking kann angegeben werden</ListGroupItem>
                                    <ListGroupItem style={{background: "#0bba0e"}}><b>Status 7:</b> Kunde befindet sich in der Behandlung</ListGroupItem>
                                    <ListGroupItem style={{background: "#2b67ff"}}><b>Status 8:</b> Kunde hat die Behandlung abgeschlossen</ListGroupItem>
                                    <ListGroupItem><b>Status -1:</b> Kunde ist nicht geeignet für die Behandlung</ListGroupItem>
                                    <ListGroupItem><b>Status -2:</b> Abdrücke sind nicht geeignet / fehlerhaft </ListGroupItem>
                                </ListGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default StatusCard;
