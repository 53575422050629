import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UploadCard from './components/UploadCard';
import ExplainCard from './components/ExplainCard';
import GalleryCard from './components/GalleryCard';

const UploadPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Impression Upload</h3>
      </Col>
    </Row>
    <Row>
      <ExplainCard />
      <UploadCard />
    </Row>
    <Row>
      <GalleryCard />
    </Row>
  </Container>
);

export default UploadPage;
