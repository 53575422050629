import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ClientsCard from './components/ClientsCard';
import ClientsTable from './components/ClientsTable';
import StatusCard from './components/StatusCard';

const ClientsPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Kundenverwaltung</h3>
      </Col>
    </Row>
    <Row>
      <ClientsCard />
      <StatusCard />
      <ClientsTable />
    </Row>
  </Container>
);

export default ClientsPage;
