/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UploadCard from './components/UploadCard';
import ExplainCard from './components/ExplainCard';
import GalleryCard from './components/GalleryCard';
import { UserContext } from '../App/UserContext';
import firebase from '../../shared/config/firebase';
import CenterLoader from '../../shared/components/CenterLoader';

const firebaseUsers = firebase.database().ref('Users');

class ProgressPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      progress: null,
      upload: false,
      loading: false
    }
  }

  componentWillMount() {
    this.setState({ loading: true })
    firebaseUsers.child(this.context.id).child('Progress').once('value', snapshot => {
      try {
        this.setState({
          progress: snapshot.val(),
          loading: false
        })
        console.log('snapshot.val()',snapshot.val());
      
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false
        })
      }
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({
        loading: false
      })
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading !== prevState.loading)
      firebaseUsers.child(this.context.id).child('Progress').once('value', snapshot => {
        try {
          this.setState({
            progress: snapshot.val(),
            loading: false
          })
        } catch (error) {
          console.log(error);
          this.setState({
            loading: false
          })
        }
      }, (errorObject) => {
        console.log(errorObject);
        this.setState({
          loading: false
        })
      });
  }
  
  renderGallery() {
    if (this.state.progress.Steps !== (null || undefined)) {
      return Object.entries(this.state.progress.Steps).reverse().map((position) => {
        if (!isNaN(parseInt(position[0]))) {
          if (typeof position[1].Images !== "undefined") {
            return <GalleryCard images={position[1].Images} step={position[0]} key={position[0]} comment={position[1].comment} />
          }
        }
      });
    }
  }

  renderUploadCard() {
    if (this.state.progress !== null) {
      console.log('Modulo gud');
      return (
        <UploadCard done={this.state.progress.done} />
      );
    }
  }

  render() {
    console.log('^&^&^&^',this.context);
    if (this.state.loading) {
      return <CenterLoader />
    } else {
      return (
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Fortschritt Upload</h3>
            </Col>
          </Row>
          <Row>
            <ExplainCard />
            {
              this.renderUploadCard()
            }
          </Row>
          <Row>
            {
              this.renderGallery()
            }
          </Row>
        </Container>
      );
    }
  }
}
ProgressPage.contextType = UserContext;

export default ProgressPage;
