import React from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import ReactTableBodyDnD from './ReactTableBodyDnD';
// import { ThemeProps } from '../../../prop-types/ReducerProps';

const DraggableOffBodyReactTable = ({
  page,
  getTableBodyProps,
  prepareRow,
}) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page.map((row) => {
      prepareRow(row);
      // console.log('prepareRow(row)', original);
      let color = '#ffffff';
      switch (row.original.statusColor) {
        case 2:
          color = '#ff8000';
          break;
        case 3:
          color = '#edd100';
          break;
        case 7:
          color = '#0bba0e';
          break;
        case 8:
          color = '#2b67ff';
          break;
        default:
          break;
      }

      return (
        <div style={{ background: color }}>
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>))}
          </tr>
        </div>
      );
    })}
  </tbody>
);

DraggableOffBodyReactTable.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

// page, getTableBodyProps, prepareRow, withDragAndDrop, updateData, theme,
const ReactTableBody = ({ page, getTableBodyProps, prepareRow }) => (
  <>
    {/* {withDragAndDrop
      ? (
        <ReactTableBodyDnD
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateData={updateData}
          theme={theme}
        />
      ) :  */}
    <DraggableOffBodyReactTable
      page={page}
      getTableBodyProps={getTableBodyProps}
      prepareRow={prepareRow}
    />
    {/* } */}
  </>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  // updateData: PropTypes.func.isRequired,
  // withDragAndDrop: PropTypes.bool.isRequired,
  // theme: ThemeProps.isRequired,
};

export default ReactTableBody;
