/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Dropzone from 'react-dropzone';
import ImageGallery from 'react-image-gallery';
import { UserContext } from '../../App/UserContext';

class ExplainCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      uploadedImages: [],
      modalIsOpen: true
    };
  }

  render() {
    const maxSize = 1048576;
    console.log(this.state.uploadedImages);
    return (
      <Col md={6} className="upload"> 
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="bold-text">CHEEEEEEEESE! 😁</h3>
            </div>
            <p>Um deinen individuellen Behandlungsplan erstellen zu können, benötigen wir ein paar Fotos von deinen Zähnen. Verwende dafür den mitgelieferten Mundspreizer. Um den Prozess zu beschleunigen, empfehlen wir dir einen Freund, Partner oder ein Familienmitglied zur Unterstützung zu holen.</p>
            <p>Wir benötigen insgesamt neun verschiedene Fotos von dir. Beispiel Fotos findest du in der mitgelieferten Anleitung sowie <a href="#">hier</a>. Bei Fragen kannst du uns jederzeit unten im Chat kontaktieren.</p>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
ExplainCard.contextType = UserContext;

export default ExplainCard;
