/* eslint-disable */
import React, { useContext } from 'react';
import {
    Card, Button, ButtonToolbar, CardBody, Col, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal
} from 'reactstrap';
import firebase from '../../../shared/config/firebase';
import { withRouter } from 'react-router-dom';
import CenterLoader from '../../../shared/components/CenterLoader';
import moment, { now } from 'moment';
import ResizableDataTable from '../../Clients/Tables/ResizableTable/components/ResizableTable';
import Accordion from "react-bootstrap/Accordion";
import AccordionCard from "react-bootstrap/Card";
import { AccordionContext, useAccordionToggle } from "react-bootstrap";
import '../../../../src/scss/component/button.scss';
import { debug } from 'firebase-functions/lib/logger';

const PlusIcon = `${process.env.PUBLIC_URL}/plus.png`;
const MinusIcon = `${process.env.PUBLIC_URL}/minus.png`;

const firebaseUsers = firebase.database().ref('Users');

function ContextAwareToggle({ eventKey, begin, end, imageValue, practiceAppointment, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div className="header-table d-flex">
            <div className='align-items-center pr-3'><label className={`appointment-status ${practiceAppointment !== '-' ? practiceAppointment == 'Ja' ? 'green' : 'red' : ''}`} style={{ display: 'flex' }}>{practiceAppointment}</label></div>
            <Table responsive header onClick={decoratedOnClick}>
                <tr>
                    {imageValue && <th><img className="corsor-pointer" src={isCurrentEventKey ? MinusIcon : PlusIcon} alt={isCurrentEventKey ? 'close' : 'open'} /></th>}
                    <th>Aligner {eventKey}</th>
                    <tr>
                        <td>Beginn</td>
                        <td>Ende</td>
                        <td>Abweichung</td>
                    </tr>
                    <tr>
                        <td>{begin}</td>
                        <td>{end}</td>
                        <td>ausstehend</td>
                    </tr>
                </tr>
            </Table>
        </div>
    );
}

function ContextAwareToggleDateDifference({ eventKey, begin, end, difference, imageValue, practiceAppointment, callback }) {
    const currentEventKey = useContext(AccordionContext);

    let color = '';
    if (difference > 2) {
        color = '#ff8000';
    }
    if (difference > 4) {
        color = 'red';
    }
    if (difference == 0) {
        color = '#0bba0e';
    }
    if (difference < 0) {
        color = '#2b67ff';
    }

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div className="header-table d-flex">
            <div className='align-items-center pr-3'><label className={`appointment-status ${practiceAppointment !== '-' ? practiceAppointment == 'Ja' ? 'green' : 'red' : ''}`}>{practiceAppointment}</label></div>
            <Table responsive header onClick={decoratedOnClick}>
                <tr style={{ background: `${color}` }}>
                    {imageValue && <th><img className="corsor-pointer" src={isCurrentEventKey ? MinusIcon : PlusIcon} alt={isCurrentEventKey ? 'close' : 'open'} /></th>}
                    <th>Aligner {eventKey}</th>
                    <tr>
                        <td>Beginn</td>
                        <td>Ende</td>
                        <td>Abweichung</td>
                    </tr>
                    <tr>
                        <td>{begin}</td>
                        <td>{end}</td>
                        <td>{difference} Tage</td>
                    </tr>
                </tr>
            </Table>
        </div>

    );
}
class ClientsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            currentClient: -1,
            tracking: '',
            loading: false,
            reactTableData: {},
        };

    }

    componentWillMount() {
        this.setState({ loading: true });

        firebaseUsers.once('value', snapshot => {
            Object.entries(snapshot.val()).map(client => {

                if (client[1].Info.type === 'client') {
                    this.setState(previousState => ({
                        clients: [...previousState.clients, client]
                    }))
                }
            })
            this.setState({
                loading: false
            });
            const data = [];
            const columns = [
                {
                    Header: 'ID',
                    accessor: 'id',
                    disableGlobalFilter: true,
                    width: 65,
                },
                {
                    Header: 'Type',
                    accessor: 'type',
                },
                {
                    Header: 'Email',
                    accessor: 'email',
                },
                {
                    Header: 'Vorname',
                    accessor: 'vorname',
                },
                {
                    Header: 'Nachname',
                    accessor: 'nachname',
                },
                {
                    Header: 'Fortschritt',
                    accessor: 'fortschritt',
                    width: 500,
                    disableGlobalFilter: true,
                    disableSortBy: true,
                },
                {
                    Header: 'Profile',
                    accessor: 'action',
                    disableGlobalFilter: true,
                    disableSortBy: true,
                },
            ];
            this.state.clients && this.state.clients.map((client, i) => {
                if (client[1].Info.status >= 7) {
                    data.push({
                        id: client[1].Info.ID,
                        type: client[1].Info.smilebold ? "S" : "L",
                        email: client[1].Info.email,
                        vorname: client[1].Info.first,
                        nachname: client[1].Info.last,
                        fortschritt: this.renderProgress(client[1]),
                        action: (
                            <Button onClick={() => this.toProfile(client[0])}>Profil</Button>
                        ),
                    });
                }
            });
            this.setState({ reactTableData: { tableHeaderData: columns, tableRowsData: data } });

        }, (errorObject) => {
            this.setState({
                loading: false
            })
        });

    }

    toProfile(id) {
        this.props.history.push({
            pathname: '/profile',
            state: { id }
        })
    }

    renderColor(difference) {
        let color = '';
        if (difference > 2) {
            color = '#ff8000';
        }
        if (difference > 4) {
            color = 'red';
        }
        if (difference == 0) {
            color = '#0bba0e';
        }
        if (difference < 0) {
            color = '#2b67ff';
        }
        return color
    }

    subAligner(progressClient) {

        return Object.entries(progressClient.Progress.Steps.reverse().slice(1)).map((progress, i) => {

            let begin = moment(progress[1].date).format('DD-MM-YYYY');
            //let end = moment(progress[1].date + 1209600000).format('DD-MM-YYYY');
            let end = moment(progress[1].end).format('DD-MM-YYYY');
            // if (!isNaN(progress[1].extra)) {
            //     //  begin = moment(progress[1].date - (progress[1].extra * 86400000)).format('DD-MM-YYYY');
            //     //                end = moment(progress[1].date + (progress[1].extra * 86400000) + 1209600000).format('DD-MM-YYYY');
            //     end = moment(progress[1].end + (progress[1].extra * 86400000)).format('DD-MM-YYYY');

            // }

            if (parseInt(progress[0]) !== parseInt(progressClient.Progress.total)) {
                if (progress[1].end === undefined) {
                    return (
                        <div className='d-flex'>
                            <div className='align-items-center pr-3'><label className={`appointment-status ${progress[1].practiceAppointment ? progress[1].practiceAppointment === 'Ja' ? 'green' : 'red' : ''}`} className="appointment-status">{progress[1].practiceAppointment ? progress[1].practiceAppointment : '-'}</label></div>
                            <Table responsive header>
                                <tr>
                                    <th width='50px'></th>
                                    <th>Aligner {parseInt(progressClient.Progress.Steps.length - (progress[0]) - 1)}</th>
                                    <tr>
                                        <td>Beginn</td>
                                        <td>Ende</td>
                                        <td>Abweichung</td>
                                    </tr>
                                    <tr>
                                        <td>{begin}</td>
                                        <td>{end}</td>
                                        <td>ausstehend</td>
                                    </tr>
                                </tr>
                            </Table>
                        </div>
                    );
                } else {
                    let difference
                    var startDate = moment(new Date(progress[1].date), "DD.MM.YYYY");
                    var currentDate = moment(new Date(progress[1].end), "DD.MM.YYYY");
                    if (progress[1].end > new Date())
                        difference = ((currentDate.diff(moment(new Date(), "DD.MM.YYYY"), "days")))
                    else {
                        // difference = ((14 - parseInt(progress[1].extra !== undefined ? progress[1].extra : 0)) - currentDate.diff(startDate, "days"));
                        difference = ((currentDate.diff(startDate, "days"))-13);

                        // if (difference > 0)
                        //     difference = -1 * (difference)
                    }
                    return (
                        <div className='d-flex'>
                            <div className='align-items-center pr-3'><label className={`appointment-status ${progress[1].practiceAppointment ? progress[1].practiceAppointment === 'Ja' ? 'green' : 'red' : ''}`}>{progress[1].practiceAppointment ? progress[1].practiceAppointment : '-'}</label></div>
                            <Table responsive header>
                                <tr style={{ background: this.renderColor(difference) }}>
                                    <th width='50px'></th>
                                    <th>Aligner {parseInt(progressClient.Progress.Steps.length - (progress[0]) - 1)}</th>
                                    <tr>
                                        <td>Beginn</td>
                                        <td>Ende</td>
                                        <td>Abweichung</td>
                                    </tr>
                                    <tr>
                                        <td>{begin}</td>
                                        <td>{end}</td>
                                        <td>{difference} Tage</td>
                                    </tr>
                                </tr>
                            </Table>
                        </div>
                    );
                }
            } else {
                return (
                    <Table responsive header>
                        <tr>
                            Behandlung abgeschlossen
                        </tr>
                    </Table>
                );
            }
        })
    }

    renderProgress(progressClient) {
        try {
            if (progressClient.Progress.Steps !== undefined) {
                return Object.entries(progressClient.Progress.Steps.slice(progressClient.Progress.Steps.length - 1)).map((progress, i) => {
                    let begin = moment(progress[1].date).format('DD-MM-YYYY');
                    let end = moment(progress[1].end).format('DD-MM-YYYY');

                    // if (!isNaN(progress[1].extra)) {
                    //     end = moment(progress[1].end + (progress[1].extra * 86400000)).format('DD-MM-YYYY');
                    // }

                    if (parseInt(progress[0]) !== parseInt(progressClient.Progress.total)) {
                        if (progress[1].end === undefined) {
                            return (
                                <div className="accordion-aligner-rpt">
                                    <Accordion>
                                        <AccordionCard>
                                            <AccordionCard.Header>
                                                {i === 0 && (
                                                    <ContextAwareToggle eventKey={progressClient.Progress.Steps.length} begin={begin} end={end} imageValue={progressClient.Progress.Steps.length == 1 ? false : true} practiceAppointment={progress[1].practiceAppointment ? progress[1].practiceAppointment : '-'}>
                                                    </ContextAwareToggle>
                                                )}
                                            </AccordionCard.Header>
                                            <Accordion.Collapse eventKey={progressClient.Progress.Steps.length}>
                                                <AccordionCard.Body>{progress.length > 1 && this.subAligner(progressClient)}</AccordionCard.Body>
                                            </Accordion.Collapse>
                                        </AccordionCard>
                                    </Accordion>
                                </div>
                            );
                        } else {
                            let difference
                            var startDate = moment(new Date(progress[1].date), "DD.MM.YYYY");
                            var currentDate = moment(new Date(progress[1].end), "DD.MM.YYYY");
                            if (progress[1].end > new Date())
                                difference = ((currentDate.diff(moment(new Date(), "DD.MM.YYYY"), "days")))
                            else {
                                // difference = ((14 - parseInt(progress[1].extra !== undefined ? progress[1].extra : 0)) - currentDate.diff
                                //     (startDate, "days"));
                                difference = ((currentDate.diff(startDate, "days"))-13);
                                // if (difference > 0)
                                //     difference = -1 * (difference)
                            }
                            return (
                                <div className="accordion-aligner-rpt">
                                    <Accordion>
                                        <AccordionCard>
                                            <AccordionCard.Header>
                                                {i === 0 && (
                                                    <ContextAwareToggleDateDifference eventKey={progressClient.Progress.Steps.length} begin={begin} end={end} difference={difference} imageValue={progressClient.Progress.Steps.length == 1 ? false : true} practiceAppointment={progress[1].practiceAppointment ? progress[1].practiceAppointment : '-'}>
                                                    </ContextAwareToggleDateDifference>
                                                )}
                                            </AccordionCard.Header>
                                            <Accordion.Collapse eventKey={progressClient.Progress.Steps.length}>
                                                <AccordionCard.Body>{progress.length > 1 && this.subAligner(progressClient)}</AccordionCard.Body>
                                            </Accordion.Collapse>
                                        </AccordionCard>
                                    </Accordion>
                                </div>
                            );
                        }
                    } else {
                        return (
                            <Table responsive header>
                                <tr>
                                    Behandlung abgeschlossen
                                </tr>
                            </Table>
                        );
                    }
                })
            } else {
                return (<td>Behandlung noch nicht gestartet</td>);
            }

        } catch (e) { console.error(e); }
    }

    render() {
        return (
            <Col md={12} lg={12}>
                {this.state.loading ? (
                    <CenterLoader />
                ) : (
                        <ResizableDataTable reactTableData={this.state.reactTableData} title='KUNDENFORTSCHRITTSLISTE' />
                    )}
            </Col>
        );
    }
}

export default withRouter(ClientsTable);
