import firebase from 'firebase';

const firebaseInit = firebase.initializeApp({
  apiKey: 'AIzaSyBj5uYE8IeaFSEquHIu0Reky3Xg69H4aZ8',
  authDomain: 'smilebold.firebaseapp.com',
  databaseURL: 'https://smilebold.firebaseio.com',
  projectId: 'smilebold',
  storageBucket: 'smilebold.appspot.com',
  messagingSenderId: '1050893646231',
  appId: '1:1050893646231:web:5667e4e408d4f1ec',
});

export default firebaseInit;
