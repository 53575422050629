/* eslint-disable */
import React from 'react';
import {
  Card, CardBody, Col, Button, Row, Modal, ButtonToolbar
} from 'reactstrap';
import firebase from '../../../shared/config/firebase';
import Dropzone from 'react-dropzone';
import * as moment from 'moment';
import { toast } from 'react-toastify';
import CenterLoader from '../../../shared/components/CenterLoader';

const storageRef = firebase.storage().ref();
const firebaseUsers = firebase.database().ref('Users');

class TreatmentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: 0,
      treatment: [],
      loading: false,
      modal: false,
      approved: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true })

    firebaseUsers.child(this.props.id).once('value', snapshot => {
      if (snapshot.val().Treatment !== undefined) {
        this.setState({
          treatment: snapshot.val().Treatment
        });
      }

      if (snapshot.val().Info !== undefined && snapshot.val().Info.approved !== undefined) {
        this.setState({
          approved: snapshot.val().Info.approved
        });
      }

      this.setState({
        loading: false
      })

    }, (errorObject) => {
      console.log(errorObject);
      this.setState({ loading: false })
    })
  }

  toggleModal() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  onDrop = (accepted, rejected, step, pos) => {

    const upload = storageRef.child(accepted[0].name + moment().unix());
    upload.put(accepted[0]).then((snapshot) => {
      upload.getDownloadURL().then((url => {
        firebaseUsers.child(this.props.id).child('Treatment').child(step).update({ [pos]: url }, (error) => {
          if (error) {
            toast.error(error.message);
          } else {
            const newTreatment = this.state.treatment.slice();
            newTreatment[step][pos] = url;
            this.setState({ treatment: newTreatment });
            console.log(this.state.treatment);
            toast.success("Bild hochgeladen");
          }
        });
      }))
    })
  }

  changeSteps = (e) => {
    this.setState({ steps: e.target.value });
  }

  setSteps = () => {
    this.setState({ treatment: [] });
    firebaseUsers.child(this.props.id).child('Progress').update({ total: (this.state.steps-1) * 2, done: 0 }, (error) => {
      if (error) {
        toast.error(error.message);
      }
    });
    for (var i = 0; i < this.state.steps; i++) {
      this.setState(prevState => ({
        treatment: [...prevState.treatment, {}]
      }))
    }
  }

  approvePatient = (proStatus) => {
    firebaseUsers.child(this.props.id).child('Info').update({
      status: 4,
      approved: true,
      pro: proStatus,
   }, error => {
      if (error) {
        toast.error(error.message)
      } else {
        this.toggleModal();
        proStatus ?
          toast.success('Patient wurde mit PRO-Version bestätigt') :
          toast.success('Patient wurde mit HOME-Version bestätigt')
      }
    })
  }
 


  disapprovePatient = () => {
    firebaseUsers.child(this.props.id).child('Info').update({
      approved: false,
      status: -1
    }, error => {
      if (error) {
        toast.error(error.message);
      } else {
        this.toggleModal();
        toast.success('Patient wurde als ungeeignet markiert')
      }
    })
  }

  renderSteps() {
    const maxSize = 1048576;
    return this.state.treatment.map((step, i) => {
      return (
        <div key={i}>
          <h1>Monat {i}</h1>
          <Row>
            <Col md={4} className="treatment__step">
              <p>Links</p>
              <Dropzone
                onDrop={(accepted, rejected) => { this.onDrop(accepted, rejected, i, 'left') }}
                accept="image/*"
                minSize={0}
                maxSize={maxSize}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                  return (
                    <div {...getRootProps()} className="upload__box" >
                      <input {...getInputProps()} />
                      {!isDragActive && <h3>Bilder hier hochladen</h3>}
                      {isDragActive && !isDragReject && <h3>Jetzt hochladen</h3>}
                      {isDragReject && <h3>Datentyp wird nicht unterstützt</h3>}
                      {isFileTooLarge && (
                        <div className="text-danger mt-2">
                          <h3>Datei ist zu groß</h3>
                        </div>
                      )}
                    </div>
                  )
                }
                }
              </Dropzone>
              <img src={this.state.treatment[i].left} />
            </Col>
            <Col md={4} className="treatment__step">
              <p>Mitte</p>
              <Dropzone
                onDrop={(accepted, rejected) => { this.onDrop(accepted, rejected, i, 'center') }}
                accept="image/*"
                minSize={0}
                maxSize={maxSize}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                  return (
                    <div {...getRootProps()} className="upload__box" >
                      <input {...getInputProps()} />
                      {!isDragActive && <h3>Bilder hier hochladen</h3>}
                      {isDragActive && !isDragReject && <h3>Jetzt hochladen</h3>}
                      {isDragReject && <h3>Datentyp wird nicht unterstützt</h3>}
                      {isFileTooLarge && (
                        <div className="text-danger mt-2">
                          <h3>Datei ist zu groß</h3>
                        </div>
                      )}
                    </div>
                  )
                }
                }
              </Dropzone>
              <img src={this.state.treatment[i].center} />
            </Col>
            <Col md={4} className="treatment__step">
              <p>Rechts</p>
              <Dropzone
                onDrop={(accepted, rejected) => { this.onDrop(accepted, rejected, i, 'right') }}
                accept="image/*"
                minSize={0}
                maxSize={maxSize}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                  return (
                    <div {...getRootProps()} className="upload__box" >
                      <input {...getInputProps()} />
                      {!isDragActive && <h3>Bilder hier hochladen</h3>}
                      {isDragActive && !isDragReject && <h3>Jetzt hochladen</h3>}
                      {isDragReject && <h3>Datentyp wird nicht unterstützt</h3>}
                      {isFileTooLarge && (
                        <div className="text-danger mt-2">
                          <h3>Datei ist zu groß</h3>
                        </div>
                      )}
                    </div>
                  )
                }
                }
              </Dropzone>
              <img src={this.state.treatment[i].right} />
            </Col>
          </Row>
          <Row>
            <Col md={6} className="treatment__step">
              <p>Oben</p>
              <Dropzone
                onDrop={(accepted, rejected) => { this.onDrop(accepted, rejected, i, 'top') }}
                accept="image/*"
                minSize={0}
                maxSize={maxSize}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                  return (
                    <div {...getRootProps()} className="upload__box" >
                      <input {...getInputProps()} />
                      {!isDragActive && <h3>Bilder hier hochladen</h3>}
                      {isDragActive && !isDragReject && <h3>Jetzt hochladen</h3>}
                      {isDragReject && <h3>Datentyp wird nicht unterstützt</h3>}
                      {isFileTooLarge && (
                        <div className="text-danger mt-2">
                          <h3>Datei ist zu groß</h3>
                        </div>
                      )}
                    </div>
                  )
                }
                }
              </Dropzone>
              <img src={this.state.treatment[i].top} />
            </Col>
            <Col md={6} className="treatment__step">
              <p>Unten</p>
              <Dropzone
                onDrop={(accepted, rejected) => { this.onDrop(accepted, rejected, i, 'down') }}
                accept="image/*"
                minSize={0}
                maxSize={maxSize}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
                  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                  return (
                    <div {...getRootProps()} className="upload__box" >
                      <input {...getInputProps()} />
                      {!isDragActive && <h3>Bilder hier hochladen</h3>}
                      {isDragActive && !isDragReject && <h3>Jetzt hochladen</h3>}
                      {isDragReject && <h3>Datentyp wird nicht unterstützt</h3>}
                      {isFileTooLarge && (
                        <div className="text-danger mt-2">
                          <h3>Datei ist zu groß</h3>
                        </div>
                      )}
                    </div>
                  )
                }
                }
              </Dropzone>
              <img src={this.state.treatment[i].down} />
            </Col>
          </Row>
          <hr />
        </div>
      );
    });
  }

  render() {
    return (
      <Col md={12}>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={`modal-dialog-primary`}
        >
          <div className="modal__header">
            <h4 className="bold-text  modal__title">Warning</h4>
          </div>
          {
            this.state.treatment.length !== 0 ?
              <div>
                <div className="modal__body">
                  <p>Ein Behandlungsplan mit insgesamt {this.state.treatment.length} Behandlungsschritten wird erstellt</p>
                </div>
                <ButtonToolbar className="modal__footer">
                  <Button onClick={() => this.approvePatient(true)}>PRO Paket</Button>
                  <Button onClick={() => this.approvePatient(false)}>HOME Paket</Button>
                </ButtonToolbar>
              </div> :
              <div>
                <div className="modal__body">
                  <p>Sind Sie sicher, dass Sie den Kunden als ungeeignet markieren wollen?</p>
                </div>
                <ButtonToolbar className="modal__footer">
                  <Button onClick={this.toggleModal}>Cancel</Button>
                  <Button onClick={this.disapprovePatient}>Patient ungeeignet</Button>
                </ButtonToolbar>
              </div>
          }

        </Modal>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Behandlungs Bilder</h5>
            </div>
            {this.state.loading ?
              <CenterLoader /> :
              <div>
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Monate</span>
                    <div className="form__form-group-field">
                      <input
                        name="steps"
                        type="number"
                        placeholder="Monate"
                        onChange={this.changeSteps}
                        value={this.state.steps}
                      />
                    </div>
                  </div>
                  <Button
                    className="btn btn-primary"
                    onClick={this.setSteps}
                  >
                    Monate generieren
                  </Button>
                  {!this.state.approved ? (this.state.treatment.length !== 0 ?
                    <Button
                      className="btn btn-primary"
                      onClick={this.toggleModal}
                    >
                      Behandlung absegnen
                    </Button> :
                    <Button
                      className="btn btn-primary"
                      onClick={this.toggleModal}
                    >
                      Patient ungeeignet
                    </Button>) : null
                  }
                </form>
                <hr />
                <div className="treatment">
                  {
                    this.renderSteps()
                  }
                </div>
              </div>
            }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default TreatmentCard;
