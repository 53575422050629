/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Dropzone from 'react-dropzone';
import ImageGallery from 'react-image-gallery';
import { toast } from 'react-toastify';
import firebase from '../../../shared/config/firebase';
import { UserContext } from '../../App/UserContext';
import * as moment from 'moment';

const storageRef = firebase.storage().ref();
const firebaseUsers = firebase.database().ref('Users');

class UploadCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
    };
  }

  onDrop = (acceptedFiles) => {
    this.setState({
      images: acceptedFiles
    })
    this.state.images.map((image) => {
      const upload = storageRef.child(image.name + moment().unix());
      upload.put(image).then((snapshot) => {
        upload.getDownloadURL().then((url => {
          firebaseUsers.child(this.context.id).child('Impressions').push().set(url, (error) => {
            if (error) {
              toast.error(error.message);
            } else {
              const status = 2;
              firebaseUsers.child(this.context.id).child('Info').update({status}, (error) => {
                if (error) {
                  toast.error(error.message);
                } else {
                  toast.success("Bild hochgeladen");
                }
              });
            }
          });
        }))
      })
    });
  }

  render() {
    const maxSize = 5242880;
    return (
      <Col md={6}> 
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="bold-text">Hier hochladen 👇</h3>
              <p>Die Bilder müssen kleiner als 5 MB sein!</p>
            </div>
            <Dropzone
              onDrop={this.onDrop}
              accept="image/*"
              minSize={0}
              maxSize={maxSize}
              multiple
            >
              {({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles}) => {
                const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
                return (
                  <div className="upload">
                    <div {...getRootProps()} className="upload__box" >
                      <input {...getInputProps()}  />
                      {!isDragActive && <h3>Bilder hier hochladen</h3>}
                      {isDragActive && !isDragReject && <h3>Jetzt hochladen</h3>}
                      {isDragReject && <h3>Datentyp wird nicht unterstützt</h3>}
                      {isFileTooLarge && (
                        <div className="text-danger mt-2">
                          <h3>Datei ist zu groß</h3>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              }
            </Dropzone>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
UploadCard.contextType = UserContext;

export default UploadCard;
