import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import UploadPage from '../Upload/index';
import ProgressPage from '../Progress/index';
import TreatmentPage from '../Treatment/index';
import ClientsPage from '../Clients/index';
import ProfilePage from '../Profile/index';
import DashboardPage from '../Dashboard/index';
import ClientProgressPage from '../ClientProgress/index';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard" component={DashboardPage} />
      <Route path="/upload" component={UploadPage} />
      <Route path="/progress" component={ProgressPage} />
      <Route path="/treatment" component={TreatmentPage} />
      <Route path="/clients" component={ClientsPage} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/clientProgress" component={ClientProgressPage} />
    </div>
  </div>
);

const Router = ({ loggedIn }) => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/" component={loggedIn ? wrappedRoutes : LogIn} />
        <Route exact path="/log_in" component={LogIn} />
      </Switch>
    </main>
  </MainWrapper>
);

Router.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

export default Router;
