/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import firebase from '../../../shared/config/firebase';
import { UserContext } from '../../App/UserContext';
import CenterLoader from '../../../shared/components/CenterLoader';

const firebaseUsers = firebase.database().ref('Users');

class GalleryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      uploadedImages: [],
      modalIsOpen: true,
      loading: false
    };
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  }

  componentDidMount() {
    this.setState({ loading: true })
    firebaseUsers.child(this.context.id).child('Impressions').on('value', snapshot => {
      this.setState({ uploadedImages: [] });
      try {
        Object.values(snapshot.val()).map((image) => {
          this.setState(previousState => ({
            uploadedImages: [...previousState.uploadedImages, { original: image, thumbnail: image }]
          }));
        }).then(() => {
          this.setState({
            loading: false
          })
        })
      } catch (error) {
        console.log(error);
        this.setState({
          loading: false
        })
      }
    }, (errorObject) => {
      console.log(errorObject);
      this.setState({
        loading: false
      })
    });
  }

  render() {
    return (
      <Col md={12}>
        <Card>
          {this.state.loading ?
            <CenterLoader /> :
            <CardBody>
              {
                this.state.uploadedImages.length > 0 ?
                  <ImageGallery items={this.state.uploadedImages} showFullscreenButton={false} showPlayButton={false} />
                  :
                  <div className="text-center">
                    <h3 className="bold-text">HIER KÖNNTEN DEINE BILDER SEIN</h3>
                  </div>
              }
            </CardBody>
          }
        </Card>
      </Col>
    );
  }
}
GalleryCard.contextType = UserContext;

export default GalleryCard;
