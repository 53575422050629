/* eslint-disable */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { UserContext } from '../../App/UserContext';

class ExplainCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      uploadedImages: [],
      modalIsOpen: true
    };
  }

  render() {
    const maxSize = 1048576;
    
    return (
      <Col md={6} className="upload"> 
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="bold-text">HALTE UNS AUF DEM LAUFENDEN! 😁</h3>
            </div>
            {this.context.status ==7 ?
            <>
            <p>Wir wünschen uns für dich die besten Ergebnisse. Deshalb sehen wir bei jedem Wechsel der Aligner nach dem Rechten. Wir können so ein optimales Ergebnis erreichen.</p>
            <p>Mach bitte mit dem aktuell getragenen Aligner im Mund Fotos von vorne und von der Seite, und nimm den Wangen- und Lippenspreizer zur Hilfe. Beispielfotos findest du in der Anleitung.</p>
            <p>Um den Prozess zu vereinfachen, empfehlen wir dir die Fotos vor einem Spiegel zu machen. Wie auch in der Anleitung vorgeschlagen. Du kannst auch gerne einen Freund, Partner oder ein Familienmitglied zur Unterstützung holen</p>
            <p>Damit der Ablauf eingehalten wird, dürfen die Aligner erst nach Freigabe gewechselt werden.</p>
            </>
             :<>
            <p>Wir wünschen uns für dich die besten Ergebnisse. Deshalb sieht unser zahnmedizinisches Expertenteam bei jedem Wechsel der Aligner bei dir nach dem Rechten.</p>
            <p>Mach bitte mit dem aktuell getragenen Aligner im Mund Fotos von vorne und von der Seite, und nimm den Wangen- und Lippenspreizer zur Hilfe.  Beispiel Fotos findest du in der mitgelieferten Anleitung.</p>
            <p>Um den Prozess zu beschleunigen, empfehlen wir dir einen Freund, Partner oder ein Familienmitglied zur Unterstützung zu holen.</p>
            <p>Also, lade bitte vor jedem Wechsel aktuelle Fotos hoch. Bei Fragen kannst du uns jederzeit unten im Chat kontaktieren.</p></>}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
ExplainCard.contextType = UserContext;

export default ExplainCard;
